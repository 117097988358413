<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Course Groups</h1>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <create-course-group-modal
            v-if="
              courseContent?.state !== 'UNAVAILABLE' &&
                courseContent?.state !== 'PUBLISHED'
            "
            @refetch="
              $apollo.queries.courseGroupsForCourseContent.refetch()
            "
          />
        </v-col>
      </v-row>
    </v-container>

    <course-group-table
      :course-groups="courseGroupsForCourseContent"
      :loading="$apollo.queries.courseGroupsForCourseContent.loading"
      @refetch="
        $apollo.queries.courseGroupsForCourseContent.refetch()
      "
    />
  </div>
</template>

<script>
import CourseGroupTable from '@/components/Course/CourseGroup/CourseGroupTable';
import CreateCourseGroupModal from '@/components/Course/CourseGroup/CreateCourseGroupModal';
import { CourseGroupsForCourseContent } from '@/graphql/CourseGroup.gql';
import { CourseContent } from '@/graphql/CourseContent.gql';

export default {
  name: 'CourseGroupsPage',
  components: {
    CourseGroupTable,
    CreateCourseGroupModal
  },
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    }
  },
  apollo: {
    courseGroupsForCourseContent: {
      query: CourseGroupsForCourseContent,
      variables() {
        return {
          id: this.courseContentId
        };
      },
      skip() {
        return !this.courseContentId;
      }
    },
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.courseContentId
        };
      },
      skip() {
        return !this.courseContentId;
      }
    }
  },
  mounted() {
    this.$apollo.queries.courseGroupsForCourseContent.refetch();
  }
};
</script>
