var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { "no-gutters": "", align: "center" } },
    [
      _c("v-col", [
        _c(
          "div",
          {
            staticClass: "text--secondary text-caption font-weight-light mt-n1",
            staticStyle: { "font-size": "10px !important" }
          },
          [_vm._v(" Version: " + _vm._s(_vm.version) + " ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }