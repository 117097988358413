var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-0 mb-0", attrs: { align: "center" } },
                    [
                      _c("h1", [_vm._v(_vm._s(_vm.course?.title))]),
                      _vm.getCourseContentState() !== "DRAFT"
                        ? _c(
                            "v-chip",
                            {
                              staticClass:
                                "ml-2 text-caption text-capitalize font-weight-bold white--text",
                              attrs: {
                                color: _vm.getCourseContentStateColour()
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getCourseContentState().toLowerCase()
                                ) + " Course Content"
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("breadcrumbs", {
                            attrs: { items: ["Courses", _vm.course?.title] }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "font-weight-bold text-caption",
                              attrs: { small: "", color: "primary" }
                            },
                            [_vm._v("v" + _vm._s(_vm.course?.version))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mr-0", attrs: { align: "center" } },
                    [
                      _c("course-timeline-modal", {
                        attrs: {
                          "course-reference-id": _vm.course?.referenceId,
                          "current-version": _vm.course?.version
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.getCourseContentState() === "UNAVAILABLE"
        ? _c(
            "v-alert",
            { attrs: { border: "left", color: "red lighten-1", dark: "" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "grow" },
                    [
                      _c("v-icon", { staticClass: "mx-2" }, [
                        _vm._v("mdi-pencil-off")
                      ]),
                      _vm._v(
                        " This version of the course has been retired. The current published version is v" +
                          _vm._s(_vm.latestCourseVersion?.version) +
                          ". "
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c("course-timeline-modal", {
                        attrs: {
                          "course-reference-id": _vm.course?.referenceId,
                          "current-version": _vm.course?.version,
                          "error-alert": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", [
        _c(
          "div",
          { staticClass: "text--primary mb-3 font-weight-bold text-h6" },
          [_vm._v(" Course Configuration ")]
        ),
        _c(
          "div",
          { staticClass: "rounded-lg white px-5 py-1 text--primary" },
          [
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Course Title ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: {
                        readonly:
                          _vm.course?.isCurrentCourseContent ||
                          !_vm.courseContentId,
                        "current-value": _vm.course?.title
                      },
                      on: { "update-value": _vm.updateTitle }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Course Description ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: {
                        readonly:
                          _vm.course?.isCurrentCourseContent ||
                          !_vm.courseContentId,
                        "current-value": _vm.course?.description
                      },
                      on: { "update-value": _vm.updateDescription }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-3",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Visible ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("v-switch", {
                      staticClass: "mt-0",
                      attrs: {
                        disabled:
                          _vm.course?.isCurrentCourseContent ||
                          !_vm.courseContentId ||
                          _vm.courseContent?.state === "PUBLISHED" ||
                          _vm.courseContent?.state === "UNAVAILABLE",
                        "input-value": _vm.course?.visible,
                        "hide-details": "",
                        inset: ""
                      },
                      on: { change: _vm.updateVisible }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-4",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Last Updated At ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(_vm.course?.updatedAt)) + " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Modules ")]
              ),
              _vm.courseContentId &&
              _vm.courseContent?.state !== "PUBLISHED" &&
              _vm.courseContent?.state !== "UNAVAILABLE"
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("create-module-modal", {
                        on: {
                          refetch: function($event) {
                            return _vm.$apollo.queries.course.refetch()
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rounded-lg white px-5 text--primary" },
            [
              _c("v-data-table", {
                staticClass: "dark--text rounded-b-lg rounded-t-0",
                attrs: {
                  items: _vm.course?.modules,
                  headers: _vm.moduleHeaders,
                  "no-data-text": "There are no modules for this course",
                  "item-key": "id"
                },
                on: {
                  "click:row": item =>
                    _vm.$router.push(
                      _vm.courseContentId
                        ? `/course-content/${_vm.courseContentId}/modules/${item.id}`
                        : `/modules/${item.id}`
                    )
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.updatedAt`,
                      fn: function({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.formatDate(item.updatedAt)) + " "
                          )
                        ]
                      }
                    },
                    {
                      key: `item.lessons`,
                      fn: function({ item }) {
                        return [_vm._v(" " + _vm._s(item.lessons.length) + " ")]
                      }
                    },
                    {
                      key: `item.editColumn`,
                      fn: function({ item }) {
                        return [
                          _vm.courseContentId
                            ? _c("delete-button", {
                                on: {
                                  delete: function($event) {
                                    return _vm.deleteModule(item.id)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.courseContentId
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        `/course-content/${_vm.courseContentId}/modules/${item.id}`
                                      )
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              )
                            : _vm._e(),
                          !_vm.courseContentId
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        `/modules/${item?.id}`
                                      )
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-eye")])],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }