<template>
  <div>
    <v-dialog v-model="dialog" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          icon
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Select Sites
        </v-card-title>

        <v-card-text class="py-2">
          <div class="page-sub-sec">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              hide-details
              class="shrink"
            />
          </div>
          <v-data-table
            v-model="selectedSites"
            :items="filteredSites"
            :headers="headers"
            class="dark--text rounded-b-lg rounded-t-0"
            :no-data-text="
              `There are no sites${
                search ? ' that match your search' : ''
              }`
            "
            :loading="$apollo.queries.sites.loading"
            loading-text="Loading..."
            :show-select="true"
          >
            <template v-slot:[`item.siteCode`]="{ item }">
              <v-chip class="text-button">{{ item.siteCode }}</v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey lighten-2" @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="addSelectedSites">
            Update Sites
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Sites } from '@/graphql/Site.gql';
import { UpdateUserSiteMemberships } from '@/graphql/UserAccount.gql';

export default {
  name: 'SiteSelector',
  props: {
    preSelectedSites: {
      default: () => []
    },
    memberRole: {
      type: String,
      required: true
    },
    existingMemberships: {
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Address', value: 'address' },
      {
        text: 'Site Code',
        value: 'siteCode'
      },
      {
        text: 'Website',
        value: 'website'
      }
    ],
    selectedSites: []
  }),
  watch: {
    dialog(value) {
      if (value) {
        this.selectedSites = [...this.preSelectedSites];
      }
    }
  },
  computed: {
    userAccountId() {
      return this.$route.params.userAccountId;
    },
    organisationId() {
      return this.$route.params.organisationId;
    },
    siteId() {
      return this.$route.params.siteId;
    },
    sitesToUse() {
      // Filter out sites that the user is already a member of with a different role
      // since they can't have multiple roles for the same site
      return (
        this.sites?.filter(
          site =>
            !this.existingMemberships.some(
              membership =>
                membership.site?.id === site?.id &&
                membership.memberRole !== this.memberRole
            )
        ) ?? []
      );
    },
    filteredSites() {
      return this.search
        ? this.sitesToUse.filter(site =>
            objectHasSearch(site, this.search)
          )
        : this.sitesToUse;
    }
  },
  apollo: {
    sites: {
      query: Sites,
      variables() {
        return {
          organisationId: this.organisationId
        };
      }
    }
  },
  methods: {
    async addSelectedSites() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateUserSiteMemberships,
          variables: {
            userAccountId: this.userAccountId,
            organisationId: this.organisationId,
            siteIds: this.selectedSites.map(site => site.id),
            memberRole: this.memberRole
          }
        });
      } catch (e) {
        console.error(e);
        this.$toast.error('Failed to update user site memberships.');
      } finally {
        this.$emit('refetch');
        this.dialog = false;
      }
    }
  }
};
</script>
