<template>
  <div class="rounded-lg white px-5 py-1 text--primary">
    <v-row no-gutters align="center" class="info-row py-2">
      <v-col cols="auto" class="mr-auto title-column">
        Node Label
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <edit-field
          :readonly="!courseContentId"
          :current-value="variation.label"
          @update-value="newValue => updateLabel(newValue)"
        />
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="info-row py-2">
      <v-col cols="auto" class="mr-auto title-column">
        Node Type
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <edit-field
          :readonly="index === 0 || !courseContentId"
          :display-value="
            variation.branchingNodeType
              .replaceAll('_', ' ')
              .toLowerCase()
          "
          :current-value="variation.branchingNodeType"
          :select-items="branchingNodeTypeItems"
          type="select"
          @update-value="
            newValue => updateBranchingNodeType(newValue)
          "
        />
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="info-row py-2">
      <v-col cols="auto" class="mr-auto title-column">
        Images
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-row align="center" no-gutters>
          <v-progress-circular
            v-if="isUploadingImages"
            indeterminate
            size="24"
            class="mr-2"
          />
          <edit-field
            :readonly="!courseContentId"
            type="file"
            add-icon
            @update-value="addImage"
          >
            <div class="d-flex">
              <div
                v-for="(image, index) in variation.images"
                :key="image.id"
                :class="
                  `${
                    index === variation.images.length - 1
                      ? ''
                      : 'mr-2'
                  }`
                "
              >
                {{ image.objectId }}
                <v-btn
                  color="error"
                  x-small
                  icon
                  @click="deleteImage(image.id)"
                >
                  <v-icon size="14">mdi-delete-outline</v-icon>
                </v-btn>
                {{
                  index === variation.images.length - 1 ? '' : ', '
                }}
              </div>
            </div>
          </edit-field>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="info-row py-2">
      <v-col cols="auto" class="mr-auto title-column">
        Videos
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-row align="center" no-gutters>
          <v-progress-circular
            v-if="isUploadingVideos"
            indeterminate
            size="24"
            class="mr-2"
          />
          <edit-field
            :readonly="!courseContentId"
            type="file"
            add-icon
            @update-value="addVideo"
          >
            <div class="d-flex">
              <div
                v-for="(video, index) in variation.videos"
                :key="video.id"
                :class="
                  `${
                    index === variation.videos.length - 1
                      ? ''
                      : 'mr-2'
                  }`
                "
              >
                {{ video.objectId }}
                <v-btn
                  color="error"
                  x-small
                  icon
                  @click="deleteVideo(video.id)"
                >
                  <v-icon size="14">mdi-delete-outline</v-icon>
                </v-btn>
                {{
                  index === variation.videos.length - 1 ? '' : ', '
                }}
              </div>
            </div>
          </edit-field>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="info-row py-2">
      <v-col cols="auto" class="mr-auto title-column">
        PDFs
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-row align="center" no-gutters>
          <v-progress-circular
            v-if="isUploadingPdfs"
            indeterminate
            size="24"
            class="mr-2"
          />
          <edit-field
            :readonly="!courseContentId"
            type="file"
            add-icon
            @update-value="addPdf"
          >
            <div class="d-flex">
              <div
                v-for="(pdf, index) in variation.pdfs"
                :key="pdf.id"
                :class="
                  `${
                    index === variation.pdfs.length - 1 ? '' : 'mr-2'
                  }`
                "
              >
                {{ pdf.objectId }}
                <v-btn
                  color="error"
                  x-small
                  icon
                  @click="deletePdf(pdf.id)"
                >
                  <v-icon size="14">mdi-delete-outline</v-icon>
                </v-btn>
                {{ index === variation.pdfs.length - 1 ? '' : ', ' }}
              </div>
            </div>
          </edit-field>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="
        variation.branchingNodeType === 'EXIT_WITH_FEEDBACK' ||
          variation.branchingNodeType === 'EXIT_WITHOUT_FEEDBACK'
      "
      no-gutters
      align="center"
      class="info-row py-2"
    >
      <v-col cols="auto" class="mr-auto title-column">
        Outcome
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <edit-field
          :readonly="!courseContentId"
          :current-value="variation.outcome"
          type="select"
          :select-items="branchingOutcomeTypeItems"
          :display-value="
            variation.outcome.replaceAll('_', ' ').toLowerCase()
          "
          @update-value="newValue => updateOutcome(newValue)"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="variation.branchingNodeType === 'EXIT_WITH_FEEDBACK'"
      no-gutters
      align="center"
      class="info-row py-2"
    >
      <v-col cols="auto" class="mr-auto title-column">
        Heading
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <edit-field
          :readonly="!courseContentId"
          :current-value="variation.feedbackHeading"
          @update-value="newValue => updateFeedbackHeading(newValue)"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="variation.branchingNodeType === 'EXIT_WITH_FEEDBACK'"
      no-gutters
      align="center"
      class="info-row py-2"
    >
      <v-col cols="auto" class="mr-auto title-column">
        Message
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <edit-field
          :readonly="!courseContentId"
          :current-value="variation.feedbackMessage"
          @update-value="newValue => updateFeedbackMessage(newValue)"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="variation.branchingNodeType === 'QUESTION'"
      no-gutters
      align="center"
      class="info-row"
    >
      <v-col cols="12">
        <v-data-table
          :items="variation.questions"
          :headers="questionHeaders"
          class="dark--text branching-node-table"
          no-data-text="There are no questions for this node"
          loading-text="Loading..."
          item-key="id"
          disable-pagination
          disable-filtering
          disable-sorting
          hide-default-footer
        >
          <template v-slot:[`header.editColumn`]="{ item }">
            <div>
              <branch-question-modal
                v-if="courseContentId && !isFrozenContent"
                :node-id="variation.id"
                :nodes="nodes"
                @create-question="addQuestion"
              />
            </div>
          </template>
          <template v-slot:[`item.editColumn`]="{ item, index }">
            <v-btn
              v-if="courseContentId && !isFrozenContent"
              color="error"
              class="pa-0 rounded-md"
              icon
              @click="deleteQuestion(index)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.iconName`]="{ item }">
            <v-icon class="mr-2" size="20"
              >mdi-{{ item.iconName }}</v-icon
            >
            {{ item.iconName }}
          </template>
          <template v-slot:[`item.correct`]="{ item }">
            <v-chip
              class="white--text font-weight-bold text-caption"
              :color="item.correct ? 'green' : 'red'"
              >{{ item.correct ? 'Correct' : 'Incorrect' }}</v-chip
            >
          </template>
          <template v-slot:[`item.linkNode`]="{ item }">
            <v-chip class="text-caption font-weight-bold">{{
              nodeForId(item.linkNode)?.label
            }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row
      v-if="courseContentId && !isFrozenContent"
      no-gutters
      align="center"
      class="py-2"
    >
      <v-col cols="auto" class="mx-auto">
        <v-btn
          color="error"
          class="text-caption"
          x-small
          @click="$emit('delete-node', variation.id)"
          >Delete Node</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditField from '@/components/Common/EditField';
import { branchingNodeTypeItems } from '@/enums/BranchingNodeType';
import BranchQuestionModal from '@/components/Question/Type/Branching/BranchQuestionModal';
import axios from 'axios';
import moment from 'moment';
import {
  CreateBucketObjectWithName,
  RequestUploadUrl
} from '@/graphql/BucketObject.gql';
import { branchingOutcomeTypeItems } from '@/enums/BranchingOutcome';

export default {
  name: 'BranchingNode',
  components: { EditField, BranchQuestionModal },
  props: {
    variation: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    nodes: {
      type: Array,
      required: true
    },
    isFrozenContent: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    questionHeaders: [
      {
        text: 'Icon Name',
        value: 'iconName',
        sortable: false
      },
      {
        text: 'Text',
        value: 'text',
        sortable: false
      },
      {
        text: 'Correct',
        value: 'correct',
        sortable: false
      },
      {
        text: 'Link Node',
        value: 'linkNode',
        sortable: false
      },
      {
        text: '',
        value: 'editColumn',
        sortable: false,
        align: 'end',
        width: '120px'
      }
    ],
    isUploadingImages: false,
    isUploadingPdfs: false,
    isUploadingVideos: false
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    branchingNodeTypeItems() {
      return branchingNodeTypeItems;
    },
    branchingOutcomeTypeItems() {
      return branchingOutcomeTypeItems;
    }
  },
  methods: {
    addQuestion(question) {
      this.variation.questions.push(question);
      this.$emit('node-updated');
    },
    nodeForId(id) {
      return this.nodes.find(node => node.id === id);
    },
    updateFeedbackHeading(newValue) {
      this.variation.feedbackHeading = newValue;
      this.$emit('node-updated');
    },
    updateFeedbackMessage(newValue) {
      this.variation.feedbackMessage = newValue;
      this.$emit('node-updated');
    },
    updateBranchingNodeType(newValue) {
      this.variation.branchingNodeType = newValue;
      this.$emit('node-updated');
    },
    updateOutcome(newValue) {
      this.variation.outcome = newValue;
      this.$emit('node-updated');
    },
    deleteQuestion(index) {
      this.variation.questions.splice(index, 1);
      this.$emit('node-updated');
    },
    async addImage(mediaFile) {
      this.isUploadingImages = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: CreateBucketObjectWithName,
          variables: {
            bucketObject: {
              objectId: `/branching-question/${
                this.variation.id
              }/${moment().unix()}-${mediaFile.file.name.replaceAll(
                '/',
                '-'
              )}`,
              fileType: mediaFile.file.type
            }
          }
        });
        const media = response.data.createBucketObjectWithName;
        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestUploadUrl,
          variables: {
            bucketObjectId: media.id
          }
        });
        const uploadUrl = uploadUrlResponse.data.requestUploadUrl;

        try {
          await axios.put(uploadUrl, mediaFile.file, {
            headers: { 'Content-Type': mediaFile.file.type }
          });

          this.variation.images.push({
            id: media.id,
            objectId: media.objectId,
            fileType: media.fileType,
            url: ''
          });

          this.$emit('node-updated');
        } catch (error) {
          console.log(error.message);
          this.$toast.error('Failed to upload file.');
        }
      } catch (e) {
        this.$toast.error('Problem adding media');
      } finally {
        this.isUploadingImages = false;
      }
    },
    deleteImage(id) {
      this.variation.images = this.variation.images.filter(
        image => image.id !== id
      );
      this.$emit('node-updated');
    },
    async addPdf(pdfFile) {
      this.isUploadingPdfs = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: CreateBucketObjectWithName,
          variables: {
            bucketObject: {
              objectId: `/branching-question/${
                this.variation.id
              }/${moment().unix()}-${pdfFile.file.name.replaceAll(
                '/',
                '-'
              )}`,
              fileType: pdfFile.file.type
            }
          }
        });
        const media = response.data.createBucketObjectWithName;
        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestUploadUrl,
          variables: {
            bucketObjectId: media.id
          }
        });
        const uploadUrl = uploadUrlResponse.data.requestUploadUrl;

        try {
          await axios.put(uploadUrl, pdfFile.file, {
            headers: { 'Content-Type': pdfFile.file.type }
          });

          this.variation.pdfs.push({
            id: media.id,
            objectId: media.objectId,
            fileType: media.fileType,
            url: ''
          });

          this.$emit('node-updated');
        } catch (error) {
          console.log(error.message);
          this.$toast.error('Failed to upload file.');
        }
      } catch (e) {
        this.$toast.error('Problem adding PDF');
      } finally {
        this.isUploadingPdfs = false;
      }
    },
    deletePdf(id) {
      this.variation.pdfs = this.variation.pdfs.filter(
        pdf => pdf.id !== id
      );
      this.$emit('node-updated');
    },
    async addVideo(mediaFile) {
      this.isUploadingVideos = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: CreateBucketObjectWithName,
          variables: {
            bucketObject: {
              objectId: `/branching-question/${
                this.variation.id
              }/${moment().unix()}-${mediaFile.file.name.replaceAll(
                '/',
                '-'
              )}`,
              fileType: mediaFile.file.type
            }
          }
        });
        const media = response.data.createBucketObjectWithName;
        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestUploadUrl,
          variables: {
            bucketObjectId: media.id
          }
        });
        const uploadUrl = uploadUrlResponse.data.requestUploadUrl;

        try {
          await axios.put(uploadUrl, mediaFile.file, {
            headers: { 'Content-Type': mediaFile.file.type }
          });

          this.variation.videos.push({
            id: media.id,
            objectId: media.objectId,
            fileType: media.fileType,
            url: ''
          });

          this.$emit('node-updated');
        } catch (error) {
          console.log(error.message);
          this.$toast.error('Failed to upload file.');
        }
      } catch (e) {
        this.$toast.error('Problem adding media');
      } finally {
        this.isUploadingVideos = false;
      }
    },
    deleteVideo(id) {
      this.variation.videos = this.variation.videos.filter(
        video => video.id !== id
      );
      this.$emit('node-updated');
    }
  }
};
</script>

<style lang="scss">
.theme--light.v-data-table.branching-node-table {
  // Target the table directly
  table {
    // Common styles for both header and body cells
    th,
    td {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    // Specific style for header cells
    th {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
}
</style>
