<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense || !!routeCourseGroupId"
        >
          <v-icon :class="routeCourseGroupId ? '' : 'mr-2'"
            >mdi-plus</v-icon
          >{{ routeCourseGroupId ? 'Add' : 'New' }} Course</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Course
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createCourseForm">
            <v-label>Title</v-label>
            <v-text-field
              v-model="title"
              :rules="rules.requiredField"
              label="Title"
              single-line
              outlined
              dense
              required
            />

            <v-label>Description</v-label>
            <v-text-field
              v-model="description"
              :rules="rules.requiredField"
              label="Description"
              single-line
              outlined
              dense
              required
            />

            <v-label>Course Group</v-label>
            <v-select
              v-model="courseGroupId"
              :rules="rules.requiredField"
              :items="courseGroupItems"
              :disabled="!!routeCourseGroupId"
              label="Course Group"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            class="primary--text"
            :disabled="creatingCourse"
            :loading="creatingCourse"
            @click="createCourse"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CreateCourse } from '@/graphql/Course.gql';
import ColourPicker from '@/components/Common/ColourPicker';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  History
} from 'tiptap-vuetify';
import {
  textColour,
  subTextColour,
  backgroundColour,
  progressFillColour
} from '@/utils/defaultColours';
import { CourseGroupsForCourseContent } from '@/graphql/CourseGroup.gql';

export default {
  name: 'CreateCourseModal',
  components: { ColourPicker, TiptapVuetify },
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    extensions: [
      History,
      Underline,
      Italic,
      ListItem, // if you need to use a list (BulletList, OrderedList)
      BulletList,
      OrderedList,
      [
        Heading,
        {
          // Options that fall into the tiptap's extension
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      HardBreak // line break on Shift + Ctrl + Enter
    ],
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    title: '',
    description: '',
    headerContent: '',
    titleColour: backgroundColour,
    titleTextColour: textColour,
    subTextColour: subTextColour,
    progressFillColour: progressFillColour,
    learningObjectives: [],
    disabled: false,
    courseGroupId: null,
    visible: true,
    creatingCourse: false
  }),
  watch: {
    dialog(dialog) {
      this.resetForm();

      if (!dialog) {
        this.$refs.createCourseForm.reset();
      } else {
        this.courseGroupId = this.routeCourseGroupId;
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUserAccount']),
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    routeCourseGroupId() {
      return this.$route.params.courseGroupId;
    },
    courseGroupItems() {
      return (this.courseGroupsForCourseContent ?? []).map(
        courseGroup => ({
          text: courseGroup.title,
          value: courseGroup.id
        })
      );
    }
  },
  apollo: {
    courseGroupsForCourseContent: {
      query: CourseGroupsForCourseContent,
      variables() {
        return {
          id: this.courseContentId
        };
      },
      skip() {
        return !this.courseContentId;
      }
    }
  },
  methods: {
    resetForm() {
      this.title = '';
      this.description = '';
      this.headerContent = '';
      this.titleColour = backgroundColour;
      this.titleTextColour = textColour;
      this.subTextColour = subTextColour;
      this.progressFillColour = progressFillColour;
      this.learningObjectives = [];
      this.visible = true;
      this.disabled = false;
      this.courseGroupId = null;
      this.creatingCourse = false;
    },
    async createCourse() {
      if (this.$refs.createCourseForm.validate()) {
        try {
          this.creatingCourse = true;
          await this.$apollo.mutate({
            mutation: CreateCourse,
            variables: {
              course: {
                title: this.title,
                description: this.description,
                headerContent: this.headerContent,
                titleColour: this.titleColour,
                titleTextColour: this.titleTextColour,
                subTextColour: this.subTextColour,
                progressFillColour: this.progressFillColour,
                learningObjectives: this.learningObjectives,
                visible: this.visible,
                disabled: this.disabled
              },
              courseGroupId: this.courseGroupId
            }
          });

          this.$emit('refetch');
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.creatingCourse = false;
        }
      }
    },
    updateTitleColour(colour) {
      this.titleColour = colour;
    }
  }
};
</script>
