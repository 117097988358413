<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" small v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
          Answer</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Option
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createDragAndDropOptionForm">
            <v-label>Source</v-label>
            <v-text-field
              v-model="source"
              :rules="rules.requiredField"
              label="Source"
              single-line
              outlined
              dense
              required
            />

            <v-label>Target</v-label>
            <v-text-field
              v-model="target"
              :rules="rules.requiredField"
              label="Target"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" class="primary--text" @click="createDragAndDropOption">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'CreateDragAndDropOptionModal',
  props: {
    existingOption: {
      default: undefined
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    source: '',
    target: ''
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$emit('reset-existing-option');
        this.$refs.createDragAndDropOptionForm.reset();
      } else {
        this.source = this.existingOption?.source ?? '';
        this.target = this.existingOption?.target ?? '';
      }
    }
  },
  methods: {
    resetForm() {
      this.source = '';
      this.target = '';
    },
    async createDragAndDropOption() {
      if (this.$refs.createDragAndDropOptionForm.validate()) {
        try {
          if (this.existingOption) {
            this.$emit('update-option', {
              id: this.existingOption.id,
              source: this.source,
              target: this.target
            });
          } else {
            this.$emit('add-option', {
              source: this.source,
              target: this.target
            });
          }
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        }
      }
    }
  }
};
</script>
