<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>{{ organisation?.companyName }}</h1>
          <breadcrumbs
            :items="['Organisation', organisation?.companyName]"
          />
        </v-col>
      </v-row>
    </v-container>

    <div class="rounded-lg white px-5 py-1 text--primary">
      <v-row no-gutters align="center" class="info-row py-2">
        <v-col cols="auto" class="mr-auto title-column">
          Name
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <edit-field
            :current-value="organisation?.companyName"
            @update-value="updateName"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-2">
        <v-col cols="auto" class="mr-auto title-column">
          Logo
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <v-row align="center" no-gutters>
            <v-progress-circular
              v-if="isUploading"
              indeterminate
              size="24"
              class="mr-2"
            />
            <edit-field
              :display-value="logoFileName"
              type="file"
              @update-value="updateLogo"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-2">
        <v-col cols="auto" class="mr-auto title-column">
          Address
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <edit-field
            :current-value="organisation?.address"
            @update-value="updateAddress"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-2">
        <v-col cols="auto" class="mr-auto title-column">
          Country
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <edit-field
            type="select"
            :current-value="organisation?.country"
            :select-items="countryOptions"
            @update-value="updateCountry"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-2">
        <v-col cols="auto" class="mr-auto title-column">
          Website
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <edit-field
            :current-value="organisation?.website"
            @update-value="updateWebsite"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-2">
        <v-col cols="auto" class="mr-auto title-column">
          Organisation Code
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <edit-field
            :current-value="organisation?.organisationCode"
            @update-value="updateOrganisationCode"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-2">
        <v-col cols="auto" class="mr-auto title-column">
          Employee Identifier
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <edit-field
            type="select"
            textClass="text-capitalize"
            :current-value="organisation?.employeeIdentifier"
            :display-value="
              organisation?.employeeIdentifier
                .replaceAll('_', ' ')
                .toLowerCase()
            "
            :select-items="employeeIdentifierOptions"
            @update-value="updateEmployeeIdentifier"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-4">
        <v-col cols="auto" class="mr-auto title-column">
          Last Updated At
        </v-col>
        <v-col cols="auto" class="ml-auto">
          {{ formatDate(organisation?.updatedAt) }}
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  Organisation,
  UpdateOrganisation
} from '@/graphql/Organisation.gql';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import { formatDate } from '@/utils/date';
import EditField from '@/components/Common/EditField';
import { countryData } from '@/data/countries';
import EmployeeIdentifier from '@/enums/EmployeeIdentifier';
import { RequestUploadUrl, CreateBucketObject } from '@/graphql/BucketObject.gql';
import axios from 'axios';

export default {
  name: 'PublishOverview',
  components: {
    Breadcrumbs,
    EditField
  },
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    },
    defaultOrganisation() {
      return {
        id: this.organisation?.id,
        logoUrl: this.organisation?.logoId,
        companyName: this.organisation?.companyName,
        address: this.organisation?.address,
        country: this.organisation?.country,
        website: this.organisation?.website,
        organisationCode: this.organisation?.organisationCode,
        employeeIdentifier: this.organisation?.employeeIdentifier
      };
    },
    employeeIdentifierOptions() {
      return Object.entries(EmployeeIdentifier).map(entry => ({
        text: entry[1],
        value: entry[0]
      }));
    },
    countryOptions() {
      return countryData.map(country => ({
        value: country.country,
        text: country.country
      }));
    },
    logoFileName() {
      const splitFileName = this.organisation.logoObject?.objectId?.split(
        '/'
      );

      return splitFileName
        ? splitFileName[splitFileName.length - 1]
        : 'N/A';
    }
  },
  apollo: {
    organisation: {
      query: Organisation,
      variables() {
        return {
          id: this.organisationId
        };
      }
    }
  },
  data() {
    return {
      isUploading: false
    };
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async updateOrganisation(organisation) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateOrganisation,
          variables: {
            organisation: organisation
          }
        });

        await this.$apollo.queries.organisation.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async updateName(companyName) {
      await this.updateOrganisation({
        ...this.defaultOrganisation,
        companyName: companyName
      });
    },
    async updateAddress(address) {
      await this.updateOrganisation({
        ...this.defaultOrganisation,
        address: address
      });
    },
    async updateCountry(country) {
      await this.updateOrganisation({
        ...this.defaultOrganisation,
        country: country
      });
    },
    async updateWebsite(website) {
      await this.updateOrganisation({
        ...this.defaultOrganisation,
        website: website
      });
    },
    async updateOrganisationCode(organisationCode) {
      await this.updateOrganisation({
        ...this.defaultOrganisation,
        organisationCode: organisationCode
      });
    },
    async updateEmployeeIdentifier(employeeIdentifier) {
      await this.updateOrganisation({
        ...this.defaultOrganisation,
        employeeIdentifier: employeeIdentifier
      });
    },
    async updateLogo(logoFile) {
      this.isUploading = true;

      if (!this.organisation.logoId) {
        const createBucketObjectResponse = await this.$apollo.mutate({
          mutation: CreateBucketObject,
          variables: {
            fileType: logoFile.file.type
          }
        });
        this.organisation.logoId = createBucketObjectResponse.data.createBucketObject.id;
        // update the organisation with the new logoId
        await this.updateOrganisation({
          ...this.defaultOrganisation,
          logoUrl: this.organisation.logoId
        });
      }

      try {
        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestUploadUrl,
          variables: {
            bucketObjectId: this.organisation.logoId
          }
        });
        const uploadUrl = uploadUrlResponse.data.requestUploadUrl;

        await axios.put(uploadUrl, logoFile.file, {
          headers: { 'Content-Type': logoFile.file.type }
        });

        await this.$apollo.queries.organisation.refetch();
      } catch (e) {
        this.$toast.error('Problem updating logo');
      } finally {
        this.isUploading = false;
      }
    }
  }
};
</script>
