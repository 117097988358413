var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingUserAccount
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-n3 mr-1",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "#00000060", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.go(-1)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("h1", [
                              _vm._v("Overview of " + _vm._s(_vm.site.name))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c("breadcrumbs", {
                        attrs: { items: ["Site", _vm.site.name] }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              { staticClass: "text--primary mb-3 font-weight-bold text-h6" },
              [_vm._v(" Site Overview ")]
            ),
            _c(
              "div",
              { staticClass: "rounded-lg white px-5 py-1 text--primary" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Site Name ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "ml-auto value-column",
                        attrs: { cols: "auto" }
                      },
                      [
                        _c("edit-field", {
                          attrs: { "current-value": _vm.site?.name },
                          on: { "update-value": _vm.updateSiteName }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Data Sharing Code ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "ml-auto value-column",
                        attrs: { cols: "auto" }
                      },
                      [
                        _c("edit-field", {
                          attrs: { "current-value": _vm.site?.siteCode },
                          on: { "update-value": _vm.updateSiteCode }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-4",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [
                        _vm._v(
                          " Automatically Approve New Data Sharing Requests "
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b({}, "span", attrs, false),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { size: "20" }
                                            },
                                            [_vm._v("mdi-help-circle-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3695785566
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "If toggled on, your organisation automatically approves all learner data share requests. If toggled off, your organisation must manually review and approve each learner data share request."
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("v-switch", {
                          staticClass: "mt-0",
                          attrs: {
                            "input-value": _vm.site?.publicSite,
                            "hide-details": "",
                            inset: ""
                          },
                          on: { change: _vm.updatePublicSite }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text--primary mb-3 font-weight-bold text-h6",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Site Management ")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("invite-user-account-modal", {
                        attrs: {
                          dense: "",
                          staff: "",
                          "organisation-id": _vm.organisationId,
                          "site-id": _vm.siteId
                        },
                        on: {
                          refetch: function($event) {
                            return _vm.$apollo.queries.siteStaff.refetch()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("organisation-staff-table", {
                attrs: {
                  staff: _vm.siteStaff,
                  loading: _vm.$apollo.queries.siteStaff.loading
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text--primary mb-3 font-weight-bold text-h6",
                      attrs: { cols: "auto" }
                    },
                    [_vm._v(" Site Learners ")]
                  )
                ],
                1
              ),
              _c("learners", {
                attrs: {
                  learners: _vm.siteLearners,
                  loading: _vm.$apollo.queries.siteLearners.loading
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }