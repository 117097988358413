<template>
  <div class="mt-3">
    <v-row align="center" class="align-center mb-0">
      <v-col class="text--primary font-weight-bold">
        Multiple Text Choice Options
      </v-col>

      <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
        <create-text-option-modal
          ref="createTextOptionModalRef"
          :existing-option="editingOption"
          @add-option="addOption"
          @update-option="updateOption"
          @reset-existing-option="editingOption = undefined"
        />
      </v-col>
    </v-row>

    <div class="rounded-lg white px-5 py-1 text--primary">
      <v-data-table
        :items="existingOptions"
        :headers="headers"
        class="dark--text rounded-b-lg rounded-t-0"
        :no-data-text="`There are no options`"
        item-key="id"
      >
        <template v-slot:[`item.correct`]="{ item }">
          <v-chip
            class="white--text font-weight-bold"
            :color="item.correct ? 'green' : 'red'"
            >{{ item.correct ? 'Correct' : 'Incorrect' }}</v-chip
          >
        </template>
        <template v-slot:[`item.editColumn`]="{ item }">
          <v-btn
            v-if="courseContentId && !isFrozenContent"
            text
            class="pa-0 rounded-md"
            icon
            @click="editOption(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="courseContentId && !isFrozenContent"
            text
            class="pa-0 rounded-md"
            icon
            @click="deleteOption(item.id)"
          >
            <v-icon color="red">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import CreateTextOptionModal from './Common/CreateTextOptionModal';

export default {
  name: 'MultipleTextChoice',
  components: { CreateTextOptionModal },
  props: {
    existingOptions: {
      type: Array,
      default: () => []
    },
    isFrozenContent: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      {
        text: 'Option',
        value: 'option',
        sortable: false
      },
      {
        text: 'Correct',
        value: 'correct',
        sortable: false
      },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    editingOption: undefined
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    }
  },
  mounted() {
    this.options = [...this.existingOptions];
  },
  methods: {
    addOption(option) {
      const nextId =
        this.existingOptions.length > 0
          ? Math.max(
              ...this.existingOptions.map(option => option.id)
            ) + 1
          : 1;
      const updatedOptions = [
        ...this.existingOptions,
        { ...option, id: nextId }
      ];
      this.$emit('options-updated', updatedOptions);
    },
    updateOption(option) {
      const updatedOptions = this.existingOptions.map(o =>
        o.id === option.id ? { ...option } : o
      );
      this.$emit('options-updated', updatedOptions);
    },
    deleteOption(id) {
      const updatedOptions = this.existingOptions.filter(
        option => option.id !== id
      );
      this.$emit('options-updated', updatedOptions);
    },
    editOption(item) {
      this.editingOption = { ...item };
      this.$refs.createTextOptionModalRef.dialog = true;
    }
  }
};
</script>
