var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [_c("h1", [_vm._v("Course Groups")])]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto d-flex", attrs: { cols: "auto" } },
                [
                  _vm.courseContent?.state !== "UNAVAILABLE" &&
                  _vm.courseContent?.state !== "PUBLISHED"
                    ? _c("create-course-group-modal", {
                        on: {
                          refetch: function($event) {
                            return _vm.$apollo.queries.courseGroupsForCourseContent.refetch()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("course-group-table", {
        attrs: {
          "course-groups": _vm.courseGroupsForCourseContent,
          loading: _vm.$apollo.queries.courseGroupsForCourseContent.loading
        },
        on: {
          refetch: function($event) {
            return _vm.$apollo.queries.courseGroupsForCourseContent.refetch()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }