<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      :items="filteredLearners"
      :headers="headers"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no learners${
          search ? ' that match your search' : ''
        }`
      "
      :loading="loading"
      loading-text="Loading..."
      item-key="id"
      @click:row="
        item =>
          $router.push(
            organisationId
              ? `/organisation/${organisationId}/learners/${item.id}`
              : `/learners/${item.id}`
          )
      "
    >
      <template v-slot:[`item.firstName`]="{ item }">
        {{ [item.firstName, item.lastName].join(' ') }}
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>
      <template v-slot:[`item.editColumn`]="{ item }">
        <v-btn v-if="archived" icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="
            $router.push(
              organisationId
                ? `/organisation/${organisationId}/learners/${item.id}`
                : `/learners/${item.id}`
            )
          "
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-tooltip
          bottom
          content-class="black--text rounded-xl"
          color="white"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.deleteRequestedAt"
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete-clock
            </v-icon>
          </template>
          <div class="pa-1">
            Deletion requested on
            {{
              new Date(item.deleteRequestedAt).toLocaleDateString()
            }}
            {{
              item.deleteRequestedBy
                ? `by ${item.deleteRequestedBy.email}`
                : ''
            }}
          </div>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';

export default {
  name: 'Learners',
  props: {
    learners: {
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    archived: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'firstName' },
      { text: 'Email', value: 'email' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    },
    filteredLearners() {
      if (!this.learners) return [];

      return this.search
        ? this.learners.filter(learner =>
            objectHasSearch(learner, this.search)
          )
        : this.learners;
    }
  }
};
</script>
