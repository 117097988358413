<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-3"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :small="dense"
        >
          <v-icon class="mr-2">mdi-eye-outline</v-icon>Change
          Visibility</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Change Visibility
        </v-card-title>

        <v-card-text class="py-2">
          <div>
            Change who can see the changes made in this version
            (currently set to
            <span
              class="text-capitalize"
              :style="`color: ${getStateColour(currentState)}`"
            >
              {{ currentState.toLowerCase() }}</span
            >):
          </div>

          <div class="mt-3">
            <v-select
              v-model="newState"
              :items="[
                { text: 'Draft', value: 'DRAFT' },
                { text: 'Testing', value: 'TESTING' },
                { text: 'Published', value: 'PUBLISHED' },
                { text: 'Unavailable', value: 'UNAVAILABLE' }
              ]"
              outlined
              hide-details
            />
          </div>

          <div
            v-if="
              newState === 'UNAVAILABLE' || newState === 'PUBLISHED'
            "
            class="mt-2"
          >
            <v-alert
              outlined
              :type="newState === 'PUBLISHED' ? 'warning' : 'error'"
              class="text-body-2"
            >
              Course content will immediately become
              {{
                newState === 'PUBLISHED'
                  ? 'available'
                  : 'unavailable and cannot be reinstated'
              }}
              to all learners.
            </v-alert>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey lighten-2" @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            class="primary--text"
            :disabled="disableSave"
            @click="saveVisibility"
          >
            Confirm{{ disableSave ? ` (${countdown}s)` : '' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { UpdateCourseContentState } from '@/graphql/CourseContent.gql';
import { getStateColour } from '@/utils/courseContent';

export default {
  name: 'ChangeVisibilityModal',
  props: {
    currentState: {
      type: String,
      default: 'DRAFT'
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    newState: 'DRAFT',
    disableSave: false,
    countdown: 0,
    timer: null
  }),
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.newState = this.currentState;
      }
    },
    newState(newState) {
      if (newState === 'UNAVAILABLE' || newState === 'PUBLISHED') {
        this.disableSave = true;
        this.countdown = 5;

        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(timer);
            this.disableSave = false;
          }
        }, 1000);
      } else {
        this.disableSave = false;
        this.countdown = 0;
        clearInterval(this.timer);
      }
    }
  },
  methods: {
    async saveVisibility() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateCourseContentState,
          variables: {
            id: this.$route.params.courseContentId,
            state: this.newState
          }
        });

        this.$emit('refetch');
        this.dialog = false;
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    getStateColour(state) {
      return getStateColour(state);
    }
  }
};
</script>
