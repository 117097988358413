<template>
  <div>
    <v-dialog v-model="dialog" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" small>
          <v-icon>mdi-plus</v-icon>Course</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          Select Course
          {{
            courseGroupingTitle ? `for ${courseGroupingTitle}` : ''
          }}
        </v-card-title>

        <v-card-text class="py-2">
          <courses-table
            ref="coursesTable"
            :courses="filteredCourses"
            :loading="$apollo.queries.currentCourseContent.loading"
            :allow-selects="true"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" class="primary--text" @click="addSelectedCourses">
            Add Courses
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CurrentCourseContent } from '@/graphql/CourseContent.gql';
import CoursesTable from '@/components/Course/CoursesTable';

export default {
  name: 'CourseSelector',
  components: { CoursesTable },
  props: {
    courseGroupingTitle: {
      default: undefined
    },
    selectedCourses: {
      default: []
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    courses() {
      if (
        this.$apollo.queries.currentCourseContent.loading ||
        !this.currentCourseContent
      )
        return [];

      let courses = [];

      for (const courseGroup of this.currentCourseContent
        .courseGroups) {
        for (const course of courseGroup.courses) {
          if (!courses.some(c => c.id === course.id)) {
            courses.push(course);
          }
        }
      }

      return courses;
    },
    filteredCourses() {
      return (
        this.courses?.filter(course => {
          if (this.selectedCourses.includes(course.referenceId)) {
            return false;
          }

          return true;
        }) ?? []
      );
    }
  },
  apollo: {
    currentCourseContent: {
      query: CurrentCourseContent,
      error(e) {
        if (e.message.includes('No value present')) {
          this.$toast.error(
            'There is no published course content, the course selector will be empty.',
            {
              id: 'courseContentFetchError'
            }
          );
        }
      }
    }
  },
  methods: {
    addSelectedCourses() {
      const selectedCourses = this.$refs.coursesTable.selectedCourses;

      if (selectedCourses.length > 0) {
        this.$emit(
          'add-selected-courses',
          selectedCourses.map(course => course.referenceId)
        );
      }

      this.dialog = false;
    }
  }
};
</script>
