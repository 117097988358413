<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Modules</h1>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <create-module-modal
            v-if="
              courseContent?.state !== 'UNAVAILABLE' &&
                courseContent?.state !== 'PUBLISHED'
            "
            @refetch="$apollo.queries.courseContent.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <modules-table
      :modules="modulesToDisplay"
      :loading="$apollo.queries.courseContent.loading"
      course-content-view
    />
  </div>
</template>

<script>
import ModulesTable from '@/components/Module/ModulesTable';
import { CourseContent } from '@/graphql/CourseContent.gql';
import CreateModuleModal from '@/components/Module/CreateModuleModal';

export default {
  name: 'CourseContentModulesPage',
  components: {
    ModulesTable,
    CreateModuleModal
  },
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    modulesToDisplay() {
      if (this.$apollo.queries.courseContent.loading) {
        return [];
      }

      let modules = [];

      for (const courseGroup of this.courseContent.courseGroups) {
        for (const course of courseGroup.courses) {
          for (const module of course.modules) {
            modules.push(module);
          }
        }
      }

      return modules;
    }
  },
  apollo: {
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  mounted() {
    this.$apollo.queries.courseContent.refetch();
  }
};
</script>
