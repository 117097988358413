<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col class="ml-n3 mr-1" cols="auto">
              <v-btn color="#00000060" icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <h1>
                {{
                  [
                    userAccount?.firstName,
                    userAccount?.lastName
                  ].join(' ')
                }}
              </h1>
            </v-col>
          </v-row>

          <breadcrumbs :items="breadcrumbItems" />
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-btn
            class="mr-3"
            color="#E5E5E5"
            :loading="resendingInvite"
            @click="resendInvite"
          >
            <v-icon class="mr-2">mdi-email-outline</v-icon>
            Resend Invite
          </v-btn>
          <v-btn
            class="mr-3"
            color="#E5E5E5"
            @click="sendPasswordReset"
          >
            <v-icon class="mr-2">mdi-lock-plus-outline</v-icon>Reset
            Password</v-btn
          >
          <v-btn v-if="!isStaffAccount" color="primary">
            <v-icon class="mr-2">mdi-share-variant-outline</v-icon
            >Export</v-btn
          >
          <v-btn
            v-if="organisationId"
            color="grey lighten-2"
            class="red--text"
            :disabled="archivingLearner"
            @click="archiveUser()"
          >
            <v-icon class="mr-2">
              {{ 'mdi-delete' }}
            </v-icon>
            Delete User
          </v-btn>
          <v-btn
            v-if="
              currentUserAccount?.isDementiaAdmin() && !isStaffAccount
            "
            class="mx-3"
            :color="
              userAccount?.deleteRequestedAt ? 'warning' : 'error'
            "
            @click="
              userAccount?.deleteRequestedAt
                ? confirmCancelDelete()
                : confirmDeleteRequest()
            "
          >
            <v-icon class="mr-2">
              {{
                userAccount?.deleteRequestedAt
                  ? 'mdi-restore'
                  : 'mdi-delete'
              }}
            </v-icon>
            {{
              userAccount?.deleteRequestedAt
                ? 'Cancel Account Deletion'
                : 'Request Account Deletion'
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="rounded-lg white px-5 py-1 text--primary">
      <v-row
        v-if="userAccount?.deleteRequestedAt"
        no-gutters
        align="center"
        class="info-row py-4 red--text"
      >
        <v-col cols="auto" class="mr-auto title-column">
          Scheduled for Deletion
        </v-col>
        <v-col cols="auto" class="ml-auto title-column">
          {{
            scheduleAccountDeletionDate(
              userAccount?.deleteRequestedAt
            )
          }}
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-4">
        <v-col cols="auto" class="mr-auto title-column">
          Email
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          {{ userAccount?.email }}
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-4">
        <v-col cols="auto" class="mr-auto title-column">
          First Name
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          {{ userAccount?.firstName }}
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-4">
        <v-col cols="auto" class="mr-auto title-column">
          Last Name
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          {{ userAccount?.lastName }}
        </v-col>
      </v-row>
      <v-row
        v-if="currentUserAccount?.isDementiaAdmin()"
        no-gutters
        align="center"
        class="info-row py-2"
      >
        <v-col cols="auto" class="mr-auto title-column">
          System Role
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          <edit-field
            type="select"
            :display-value="
              userAccount?.systemRole
                .replaceAll('_', ' ')
                .toLowerCase()
            "
            :current-value="userAccount?.systemRole"
            :select-items="systemRoleOptions"
            @update-value="newValue => updateSystemRole(newValue)"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-4">
        <v-col cols="auto" class="mr-auto title-column">
          Gender
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          {{ userAccount?.gender ?? '-' }}
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-4">
        <v-col cols="auto" class="mr-auto title-column">
          Job Role
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          {{ userAccount?.jobRole ?? '-' }}
        </v-col>
      </v-row>
      <v-row
        v-if="isUserOrgStaff && organisationId"
        no-gutters
        align="center"
        class="info-row py-2"
      >
        <v-col cols="auto" class="mr-auto title-column">
          Site Admin For
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          <v-row align="center" no-gutters>
            <v-col cols="auto">
              <div>
                {{
                  siteAdminForSites
                    .map(membership => membership.site.name)
                    .join(', ') ?? '-'
                }}
              </div>
            </v-col>
            <v-col cols="auto">
              <site-selector
                :existing-memberships="membershipsForUserAccount"
                :pre-selected-sites="
                  siteAdminForSites.map(membership => membership.site)
                "
                :disabled="!isCurrentUserOrgAdmin"
                member-role="SITE_ADMIN"
                @refetch="refetchUserAccount"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="isUserOrgStaff && organisationId"
        no-gutters
        align="center"
        class="info-row py-2"
      >
        <v-col cols="auto" class="mr-auto title-column">
          Site Viewer For
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          <v-row align="center" no-gutters>
            <v-col cols="auto">
              <div>
                {{
                  siteViewerForSites
                    .map(membership => membership.site.name)
                    .join(', ') ?? '-'
                }}
              </div>
            </v-col>
            <v-col cols="auto">
              <site-selector
                :existing-memberships="membershipsForUserAccount"
                :pre-selected-sites="
                  siteViewerForSites.map(
                    membership => membership.site
                  )
                "
                :disabled="!isCurrentUserOrgAdmin"
                member-role="SITE_VIEWER"
                @refetch="refetchUserAccount"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="info-row py-4">
        <v-col cols="auto" class="mr-auto title-column">
          Last Active At
        </v-col>
        <v-col cols="auto" class="ml-auto value-column">
          {{
            userAccount?.updatedAt
              ? formatDate(userAccount?.updatedAt)
              : ''
          }}
        </v-col>
      </v-row>
    </div>

    <div v-if="currentUserAccount?.isDementiaAdmin()" class="mt-8">
      <div class="text--primary mb-3 font-weight-bold text-h6">
        Testing Features
      </div>
      <div class="rounded-lg white px-5 py-1 text--primary">
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Ask Annie Anything
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-switch
              v-model="userAccount.aaaAccess"
              :disabled="
                togglingAaaAccess ||
                  $apollo.queries.userAccount.loading
              "
              inset
              hide-details
              class="my-0"
              @click="toggleAaaAccess"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            View Test Course Data
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-switch
              v-model="userAccount.isTestUser"
              :disabled="
                togglingTestUser ||
                  $apollo.queries.userAccount.loading
              "
              inset
              hide-details
              class="my-0"
              @click="toggleTestUser"
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-if="!isStaffAccount" class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Summary of Course Completion
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="text-caption"
            color="primary"
            :to="lessonCompletionRoute"
            small
          >
            <v-icon class="mr-2">mdi-eye-outline</v-icon>
            View Lesson Completions
          </v-btn>
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="formattedLessonCompletionsForUser"
          :headers="summaryOfCourseCompletionHeaders"
          :loading="$apollo.queries.lessonCompletionsForUser.loading"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No completions recorded for this user"
          loading-text="Loading – this can take up to 60 seconds..."
          item-key="id"
        >
          <template v-slot:[`item.totalModules`]="{ item }">
            {{ item.completedModules }}/{{ item.totalModules }}
          </template>
          <template v-slot:[`item.totalLessons`]="{ item }">
            {{ item.completedLessons }}/{{ item.totalLessons }}
          </template>
          <template v-slot:[`item.courseAvailability`]="{ item }">
            <span
              class="text-capitalize"
              :style="
                `color: ${getStateColour(item.courseContentState)}`
              "
            >
              {{
                item.courseContentState
                  .replaceAll('_', ' ')
                  .toLowerCase()
              }}
            </span>
          </template>
        </v-data-table>
      </div>
    </div>

    <div
      v-if="currentUserAccount?.isDementiaAdmin() && !organisationId"
      class="mt-8"
    >
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Data Sharing
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="membershipsForUserAccount"
          :headers="dataSharingHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No memberships found for this user"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.site.name`]="{ item }">
            {{ item.site?.name ?? '-' }}
          </template>
          <template v-slot:[`item.bundle.name`]="{ item }">
            {{ item.bundle?.name ?? '-' }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.archivedAt`]="{ item }">
            {{ item.archivedAt ? formatDate(item.archivedAt) : '-' }}
          </template>
          <template v-slot:[`item.membershipState`]="{ item }">
            <v-chip
              :color="getMembershipStateColour(item.membershipState)"
              class="text-capitalize text-caption white--text"
            >
              {{
                item.membershipState
                  .replaceAll('_', ' ')
                  .toLowerCase()
              }}
            </v-chip>
          </template>
          <template v-slot:[`item.memberRole`]="{ item }">
            <span class="text-capitalize">
              {{ item.memberRole.replaceAll('_', ' ').toLowerCase() }}
            </span>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="showDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Request Account Deletion</v-card-title
        >
        <v-card-text>
          Are you sure you want to request deletion of this account?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn
            color="error"
            :loading="requestingDeletion"
            @click="requestDeletion"
          >
            Request Deletion
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCancelDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Cancel Account Deletion Request</v-card-title
        >
        <v-card-text>
          Are you sure you want to cancel the deletion request for
          this account?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showCancelDeleteDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="warning"
            :loading="cancelingDeletion"
            @click="cancelDeletion"
          >
            Cancel Deletion Request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  UserAccount,
  ToggleTestUser,
  ToggleAaaAccess,
  ScheduleAccountDeletion,
  CancelAccountDeletion,
  ResendInvite,
  UpdateSystemRole
} from '@/graphql/UserAccount.gql';
import { Organisation } from '@/graphql/Organisation.gql';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import { formatDate } from '@/utils/date';
import {
  ArchiveMembership,
  MembershipsForUserAccount,
  MembershipsForCurrentUserAccount
} from '@/graphql/Membership.gql';
import { LessonCompletionsForUser } from '@/graphql/LessonCompletion.gql';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { getStateColour } from '@/utils/courseContent';
import DeleteButton from '@/components/Common/DeleteButton.vue';
import { Site } from '@/graphql/Site.gql';
import moment from 'moment';
import { getMembershipStateColour } from '@/utils/membership';
import SiteSelector from '@/components/Site/SiteSelector.vue';
import { systemRolesItems } from '@/enums/SystemRoles';
import EditField from '@/components/Common/EditField';

export default {
  name: 'UserDetails',
  components: { Breadcrumbs, DeleteButton, SiteSelector, EditField },
  data: () => ({
    archivingLearner: false,
    togglingTestUser: false,
    togglingAaaAccess: false,
    summaryOfCourseCompletionHeaders: [
      { text: 'Course', value: 'course.title' },
      { text: 'Modules Completed', value: 'totalModules' },
      { text: 'Lessons Completed', value: 'totalLessons' },
      { text: 'Course Availability', value: 'courseAvailability' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    dataSharingHeaders: [
      { text: 'Organisation', value: 'organisation.companyName' },
      { text: 'Site', value: 'site.name' },
      { text: 'Bundle', value: 'bundle.name' },
      { text: 'State', value: 'membershipState' },
      { text: 'Role', value: 'memberRole' },
      { text: 'Initiated', value: 'createdAt' },
      { text: 'Terminated', value: 'archivedAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    showDeleteDialog: false,
    requestingDeletion: false,
    showCancelDeleteDialog: false,
    cancelingDeletion: false,
    resendingInvite: false,
    selectedSites: []
  }),
  computed: {
    ...mapState('user', ['currentUserAccount', 'loadingUserAccount']),
    userAccountId() {
      return this.$route.params.userAccountId;
    },
    organisationId() {
      return this.$route.params.organisationId;
    },
    siteId() {
      return this.$route.params.siteId;
    },
    lessonCompletionRoute() {
      if (this.organisationId || this.siteId) {
        return `/organisation/${this.organisationId}/learners/${this.userAccountId}/completion-data`;
      }

      return `/learners/${this.userAccountId}/completion-data`;
    },
    isStaffAccount() {
      return this.$route.path.includes('/staff/');
    },
    isCurrentUserOrgAdmin() {
      return (
        this.currentUserAccount?.isDementiaAdmin() ||
        this.membershipsForCurrentUserAccount.find(
          membership =>
            membership.organisation.id === this.organisationId &&
            !membership.archivedAt
        )?.memberRole === 'ORG_ADMIN'
      );
    },
    isUserOrgStaff() {
      const foundMembership = this.membershipsForCurrentUserAccount.find(
        membership =>
          membership.organisation.id === this.organisationId
      );

      return (
        this.currentUserAccount?.isDementiaAdmin() ||
        foundMembership?.memberRole === 'ORG_ADMIN' ||
        foundMembership?.memberRole === 'ORG_VIEWER'
      );
    },
    siteAdminForSites() {
      return (
        this.membershipsForUserAccount?.filter(
          membership => membership.memberRole === 'SITE_ADMIN'
        ) ?? []
      );
    },
    siteViewerForSites() {
      return (
        this.membershipsForUserAccount?.filter(
          membership => membership.memberRole === 'SITE_VIEWER'
        ) ?? []
      );
    },
    breadcrumbItems() {
      let breadcrumbs = [];

      if (this.organisationId || this.siteId) {
        breadcrumbs.push('Organisation');
        breadcrumbs.push(this.organisation?.companyName);
      }

      if (this.siteId) {
        breadcrumbs.push('Site');
        breadcrumbs.push(this.site?.name);
      }

      breadcrumbs.push(this.isStaffAccount ? 'Staff' : 'Learner');

      breadcrumbs.push(
        [
          this.userAccount?.firstName,
          this.userAccount?.lastName
        ].join(' ')
      );

      return breadcrumbs;
    },
    formattedLessonCompletionsForUser() {
      const courseMap = new Map();

      // Only count lessons that were actually completed (correct answers)
      const completedLessonRefs = new Set(
        this.lessonCompletionsForUser
          ?.filter(
            comp => comp.correctQuestions === comp.totalQuestions
          )
          .map(comp => comp.referenceId) || []
      );

      // Process each completion to build course data
      this.lessonCompletionsForUser?.forEach(completion => {
        const {
          courseContentCompleted,
          courseCompleted
        } = completion;

        // Skip if we've already processed this course
        if (courseMap.has(courseCompleted.id)) return;

        // Find the course in courseGroups
        let targetCourse = courseCompleted; // Use the courseCompleted directly
        let courseContentState = courseContentCompleted.state;

        // Calculate totals and completions
        let completedModules = 0;
        let totalLessons = 0;
        let completedLessons = 0;

        for (const module of targetCourse.modules) {
          totalLessons += module.lessons.length;

          // Count completed lessons in this module
          const moduleLessonsCompleted = module.lessons.filter(
            lesson =>
              completedLessonRefs.has(lesson.completionReferenceId)
          ).length;

          completedLessons += moduleLessonsCompleted;

          // Module is complete only if all its lessons are complete
          if (moduleLessonsCompleted === module.lessons.length) {
            completedModules++;
          }
        }

        courseMap.set(courseCompleted.id, {
          course: targetCourse,
          completedModules,
          totalModules: targetCourse.modules.length,
          completedLessons,
          totalLessons,
          courseContentState
        });
      });

      return [...courseMap.values()];
    },
    systemRoleOptions() {
      return systemRolesItems;
    }
  },
  apollo: {
    userAccount: {
      query: UserAccount,
      variables() {
        return {
          id: this.userAccountId
        };
      }
    },
    lessonCompletionsForUser: {
      query: LessonCompletionsForUser,
      variables() {
        return {
          userAccountId: this.userAccountId
        };
      }
    },
    organisation: {
      query: Organisation,
      variables() {
        return {
          id: this.organisationId
        };
      },
      skip() {
        return !this.organisationId;
      }
    },
    site: {
      query: Site,
      variables() {
        return {
          id: this.siteId
        };
      },
      skip() {
        return !this.siteId;
      }
    },
    membershipsForUserAccount: {
      query: MembershipsForUserAccount,
      variables() {
        return { userAccountId: this.userAccountId };
      },
      skip() {
        return !this.userAccountId;
      }
    },
    membershipsForCurrentUserAccount: {
      query: MembershipsForCurrentUserAccount
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async updateSystemRole(newSystemRole) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateSystemRole,
          variables: {
            userAccountId: this.userAccountId,
            systemRole: newSystemRole
          }
        });
        await this.$apollo.queries.userAccount.refetch();
      } catch {
        this.$toast.error(
          'There was a problem changing the system role, please try again.'
        );
      }
    },
    async archiveUser() {
      this.archivingLearner = true;

      try {
        await this.$apollo.mutate({
          mutation: ArchiveMembership,
          variables: {
            organisationId: this.organisationId,
            userAccountId: this.userAccountId
          }
        });
        this.$router.push(
          `/organisation/${this.organisationId}/archived-learners`
        );
      } catch {
        this.archivingLearner = false;
        this.$toast.error(
          `There was a problem archiving this ${
            this.isStaffAccount ? 'staff account' : 'learner'
          }, please try again.`
        );
      }
    },
    async sendPasswordReset() {
      const auth = getAuth();

      try {
        await sendPasswordResetEmail(auth, this.userAccount.email);
        this.$toast.success('Password reset link sent to the user');
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
    async toggleTestUser() {
      this.togglingTestUser = true;

      try {
        await this.$apollo.mutate({
          mutation: ToggleTestUser,
          variables: {
            userAccountId: this.userAccountId
          }
        });
        await this.$apollo.queries.userAccount.refetch();
        this.togglingTestUser = false;
      } catch {
        this.togglingTestUser = false;
        this.$toast.error(
          'There was a problem toggling test course data for this learner, please try again.'
        );
      }
    },
    async toggleAaaAccess() {
      this.togglingAaaAccess = true;

      try {
        await this.$apollo.mutate({
          mutation: ToggleAaaAccess,
          variables: {
            userAccountId: this.userAccountId
          }
        });
        await this.$apollo.queries.userAccount.refetch();
        this.togglingAaaAccess = false;
      } catch {
        this.togglingAaaAccess = false;
        this.$toast.error(
          'There was a problem toggling Ask Annie Anything for this learner, please try again.'
        );
      }
    },
    getStateColour(state) {
      return getStateColour(state);
    },
    confirmDeleteRequest() {
      this.showDeleteDialog = true;
    },
    async requestDeletion() {
      this.requestingDeletion = true;
      try {
        await this.$apollo.mutate({
          mutation: ScheduleAccountDeletion,
          variables: {
            userAccountId: this.userAccountId
          }
        });
        this.showDeleteDialog = false;
        this.$toast.success('Account deletion has been scheduled');
        await this.$apollo.queries.userAccount.refetch();
      } catch (error) {
        this.$toast.error('Failed to schedule account deletion');
      } finally {
        this.requestingDeletion = false;
      }
    },
    confirmCancelDelete() {
      this.showCancelDeleteDialog = true;
    },
    async cancelDeletion() {
      this.cancelingDeletion = true;
      try {
        await this.$apollo.mutate({
          mutation: CancelAccountDeletion,
          variables: {
            userAccountId: this.userAccountId
          }
        });
        this.showCancelDeleteDialog = false;
        this.$toast.success('Account deletion has been canceled');
        await this.$apollo.queries.userAccount.refetch();
      } catch (error) {
        this.$toast.error('Failed to cancel account deletion');
      } finally {
        this.cancelingDeletion = false;
      }
    },
    async resendInvite() {
      this.resendingInvite = true;
      try {
        await this.$apollo.mutate({
          mutation: ResendInvite,
          variables: {
            userAccountId: this.userAccountId
          }
        });
        this.$toast.success('Invite has been resent');
      } catch (error) {
        this.$toast.error('Failed to resend invite');
      } finally {
        this.resendingInvite = false;
      }
    },
    scheduleAccountDeletionDate(date) {
      return formatDate(moment(date).add(1, 'year'));
    },
    getMembershipStateColour(state) {
      return getMembershipStateColour(state);
    },
    refetchUserAccount() {
      this.$apollo.queries.membershipsForUserAccount.refetch();
    }
  }
};
</script>
