var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("h1", [_vm._v(_vm._s(_vm.courseContent?.title))]),
                  _c("breadcrumbs", {
                    attrs: {
                      items: ["Publish Content", _vm.courseContent?.title]
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ml-auto d-flex", attrs: { cols: "auto" } },
                [
                  _vm.courseContent?.state !== "UNAVAILABLE" &&
                  _vm.courseContent?.state !== "PUBLISHED"
                    ? _c("change-visibility-modal", {
                        attrs: { "current-state": _vm.courseContent?.state },
                        on: {
                          refetch: function($event) {
                            return _vm.$apollo.queries.courseContent.refetch()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          attrs: {
            "active-class": "active-tab",
            "background-color": "primary",
            dark: ""
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.items, function(item) {
          return _c("v-tab", { key: item }, [_vm._v(" " + _vm._s(item) + " ")])
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "rounded-b-lg white px-5 py-1 text--primary" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Update Name ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("edit-field", {
                          attrs: { "current-value": _vm.courseContent?.title },
                          on: { "update-value": _vm.updateTitle }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Version Number ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("edit-field", {
                          attrs: {
                            "current-value": _vm.courseContent?.version
                          },
                          on: { "update-value": _vm.updateVersion }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-4",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Show Release Notes to User ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c(
                          "div",
                          [
                            _c("v-switch", {
                              key: _vm.showToUserKey,
                              staticClass: "my-n2",
                              attrs: {
                                "input-value":
                                  _vm.courseContent?.releaseNotes?.showToUser ??
                                  false,
                                inset: "",
                                "hide-details": ""
                              },
                              on: { change: _vm.updateShowReleaseNotesToUser }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Release Notes Title (Visible To Learners) ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("edit-field", {
                          attrs: {
                            "current-value":
                              _vm.courseContent?.releaseNotes?.title
                          },
                          on: { "update-value": _vm.updateReleaseNotesTitle }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Release Notes (Visible To Learners) ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("edit-field", {
                          attrs: {
                            "current-value":
                              _vm.courseContent?.releaseNotes?.releaseNotes
                          },
                          on: { "update-value": _vm.updateReleaseNotes }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-4",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Visibility ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-capitalize",
                            style: `color: ${_vm.getStateColour(
                              _vm.courseContent?.state
                            )}`
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.courseContent?.state.toLowerCase()) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-2",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Update Summary ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("edit-field", {
                          attrs: {
                            "current-value": _vm.courseContent?.summary
                          },
                          on: { "update-value": _vm.updateSummary }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "info-row py-4",
                    attrs: { "no-gutters": "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mr-auto title-column",
                        attrs: { cols: "auto" }
                      },
                      [_vm._v(" Last Updated At ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatDate(_vm.courseContent?.updatedAt)
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "pa-5" },
              [
                _c("div", { staticClass: "text--secondary text-body-2" }, [
                  _vm._v(
                    " The following information outlines how the Ask Annie app will behave when content changes occur: "
                  )
                ]),
                _c("v-divider", { staticClass: "my-5" }),
                _c("div", { staticClass: "primary--text" }, [
                  _c("h3", [_vm._v("Added Questions to Lessons")]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [_vm._v(" Users with 100%: ")]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(
                      " The lesson will continue to be marked as 100%. Next time they attempt it, they will see the new question. This lesson still appears in the backend, PDF export, and CSV export "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [_vm._v(" Users scored less than 100%: ")]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(
                      " The lesson remain as incomplete. This lesson still appears in the backend, PDF export, and CSV export. "
                    )
                  ])
                ]),
                _c("v-divider", { staticClass: "my-5" }),
                _c("div", { staticClass: "primary--text" }, [
                  _c("h3", [_vm._v("Archived Questions from Lessons")]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [_vm._v(" Users with 100%: ")]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(
                      " The lesson will continue to be marked as 100%. Next time they attempt it, they will see the new question. This lesson still appears in the backend, PDF export, and CSV export. "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [_vm._v(" Users scored less than 100%: ")]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(
                      " The lesson remain as incomplete. This lesson still appears in the backend, PDF export, and CSV export. "
                    )
                  ])
                ]),
                _c("v-divider", { staticClass: "my-5" }),
                _c("div", { staticClass: "primary--text" }, [
                  _c("h3", [_vm._v("Archived Module")]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [_vm._v(" User have attempted lessons in the module: ")]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(
                      " The user will no longer see the module in the mobile app. However, any completed lessons will continue to be visible in the backend, PDF export, and CSV report. . "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [_vm._v(" User has not attempted lessons in the module: ")]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(
                      " All lessons remain as incomplete. This module will not appear in learner’s view on the Ask Annie website. "
                    )
                  ])
                ]),
                _c("v-divider", { staticClass: "my-5" }),
                _c("div", { staticClass: "primary--text" }, [
                  _c("h3", [_vm._v("Archived Course")]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [_vm._v(" User have completed any content in a course: ")]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(
                      " The learner will no longer see the course or content associated with it. However, any completed lessons will continue to be visible in the backend, PDF export, and CSV report. "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-body-2 mt-5 mb-2" },
                    [
                      _vm._v(
                        " User have not completed any content in the course: "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "text-body-2 font-weight-light" }, [
                    _vm._v(" The user will no longer see the course. ")
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }