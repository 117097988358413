<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>DA Staff</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A list of all Dementia Australia staff that are
                currently on the system.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <invite-user-account-modal
            staff
            @refetch="$apollo.queries.staffAccounts.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <dementia-staff-table
      :staff="staffAccounts"
      :loading="$apollo.queries.staffAccounts.loading"
    />
  </div>
</template>

<script>
import DementiaStaffTable from '@/components/Staff/DementiaStaffTable';
import InviteUserAccountModal from '@/components/Common/InviteUserAccountModal';
import { StaffAccounts } from '@/graphql/UserAccount.gql';

export default {
  name: 'UserList',
  components: {
    DementiaStaffTable,
    InviteUserAccountModal
  },
  apollo: {
    staffAccounts: {
      query: StaffAccounts
    }
  }
};
</script>
