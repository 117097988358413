var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { "no-gutters": "", align: "center" } },
    [
      _c("v-col", { attrs: { cols: "auto" } }, [_vm._v(_vm._s(_vm.fileName))]),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                disabled: _vm.$apollo.queries.generateSignedUrl.loading,
                icon: ""
              },
              on: { click: _vm.openImage }
            },
            [_c("v-icon", [_vm._v("mdi-eye-outline")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }