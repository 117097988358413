<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>{{ module?.title }}</h1>

          <v-row no-gutters align="center">
            <v-col cols="auto">
              <breadcrumbs :items="['Modules', module?.title]" />
            </v-col>
            <v-col cols="auto">
              <v-chip
                small
                color="primary"
                class="font-weight-bold text-caption"
                >v{{ module?.version }}</v-chip
              >
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-row align="center" class="mr-0">
            <module-timeline-modal
              :module-reference-id="module?.referenceId"
              :current-version="module?.version"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="text--primary mb-3 font-weight-bold text-h6">
        Module Configuration
      </div>
      <div class="rounded-lg white px-5 py-1 text--primary">
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Module Title
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :readonly="!courseContentId"
              :current-value="module?.title"
              @update-value="updateTitle"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Card Title Colour
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <colour-picker
              :readonly="!courseContentId"
              :current-colour="module?.cardTitleColour"
              @change="updateCardTitleColour"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Card Subtext Colour
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <colour-picker
              :readonly="!courseContentId"
              :current-colour="module?.cardSubtextColour"
              @change="updateCardSubtextColour"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Card Background Colour
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <colour-picker
              :readonly="!courseContentId"
              :current-colour="module?.cardBackgroundColour"
              @change="updateCardBackgroundColour"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Progress Fill Colour
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <colour-picker
              :readonly="!courseContentId"
              :current-colour="module?.progressFillColour"
              @change="updateProgressFillColour"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-3">
          <v-col cols="auto" class="mr-auto title-column">
            Visible
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-switch
              :disabled="
                !courseContentId ||
                  courseContent?.state === 'PUBLISHED' ||
                  courseContent?.state === 'UNAVAILABLE'
              "
              :input-value="module?.visible"
              @change="updateVisible"
              hide-details
              class="mt-0"
              inset
            ></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Last Updated At
          </v-col>
          <v-col cols="auto" class="ml-auto">
            {{ formatDate(module?.updatedAt) }}
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Module Introduction - Header Media
        </v-col>

        <v-col
          v-if="
            courseContentId &&
              courseContent?.state !== 'PUBLISHED' &&
              courseContent?.state !== 'UNAVAILABLE'
          "
          cols="auto"
        >
          <create-content-modal
            :required-content-type="[
              ContentType.IMAGE,
              ContentType.VIDEO
            ]"
            :disabled="!!module?.headerMedia"
            @create-content="saveHeaderMedia"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            module?.headerMedia
              ? [JSON.parse(module?.headerMedia)]
              : []
          "
          :headers="moduleHeaderMediaHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No module introduction header media"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.content`]="{ item }">
            <bucket-object-format :bucket-object-id="item.content" />
          </template>
          <template v-slot:[`item.contentType`]="{ item }">
            <v-icon v-if="item.contentType == ContentType.IMAGE"
              >mdi-image-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.VIDEO"
              >mdi-video-outline</v-icon
            >
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-row justify="end" align="center">
              <edit-content-modal
                :required-content-type="[
                  ContentType.IMAGE,
                  ContentType.VIDEO
                ]"
                :current-content="item"
                @save-content="saveHeaderMedia"
              />
              <delete-button
                v-if="courseContentId"
                @delete="deleteModuleHeaderMedia"
              />
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Module Introduction - Content
        </v-col>

        <v-col
          v-if="
            courseContentId &&
              courseContent?.state !== 'PUBLISHED' &&
              courseContent?.state !== 'UNAVAILABLE'
          "
          cols="auto"
        >
          <create-content-modal @create-content="createContent" />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            module?.moduleIntroduction?.map(fb => JSON.parse(fb)) ??
              []
          "
          :headers="moduleIntroductionHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No module introduction content"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.contentType`]="{ item }">
            <v-icon v-if="item.contentType == ContentType.TEXT"
              >mdi-text-shadow</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.VIDEO"
              >mdi-video-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.IMAGE"
              >mdi-image-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.PDF"
              >mdi-file-pdf-outline</v-icon
            >
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-row justify="end" align="center">
              <edit-content-modal
                :current-content="item"
                @save-content="updateContent"
              />
              <delete-button
                v-if="courseContentId"
                @delete="deleteModuleIntroduction(item.id)"
              />
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Lessons
        </v-col>

        <v-col
          v-if="
            courseContentId &&
              courseContent?.state !== 'PUBLISHED' &&
              courseContent?.state !== 'UNAVAILABLE'
          "
          cols="auto"
        >
          <create-lesson-modal
            @refetch="$apollo.queries.module.refetch()"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="module?.lessons ?? []"
          :headers="lessonHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="There are no lessons for this module"
          item-key="id"
          @click:row="
            item =>
              $router.push(
                courseContentId
                  ? `/course-content/${courseContentId}/lessons/${item.id}`
                  : `/lessons/${item.id}`
              )
          "
        >
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>
          <template v-slot:[`item.variations`]="{ item }">
            {{ item.variations.length }}
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <delete-button
              v-if="courseContentId"
              @delete="deleteLesson(item.id)"
            />
            <v-btn
              v-if="courseContentId"
              icon
              @click="
                $router.push(
                  `/course-content/${courseContentId}/lessons/${item.id}`
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="!courseContentId"
              icon
              @click="$router.push(`/lessons/${item?.id}`)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Module,
  AddLessonToModule,
  DeleteLessonFromModule,
  UpdateModule
} from '@/graphql/Module.gql';
import { formatDate } from '@/utils/date';
import ColourPicker from '@/components/Common/ColourPicker';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import LessonSelector from '@/components/Lesson/LessonSelector';
import CreateContentModal from '@/components/Common/CreateContentModal';
import ContentType from '@/enums/ContentType';
import BucketObjectFormat from '@/components/Common/BucketObjectFormat';
import EditField from '@/components/Common/EditField';
import ModuleTimelineModal from '@/components/Module/ModuleTimelineModal';
import CreateLessonModal from '@/components/Lesson/CreateLessonModal';
import DeleteButton from '@/components/Common/DeleteButton.vue';
import EditContentModal from '@/components/Common/EditContentModal';
import { CourseContent } from '@/graphql/CourseContent.gql';

export default {
  name: 'ModulePage',
  components: {
    ColourPicker,
    Breadcrumbs,
    LessonSelector,
    CreateContentModal,
    BucketObjectFormat,
    EditField,
    ModuleTimelineModal,
    CreateLessonModal,
    DeleteButton,
    EditContentModal
  },
  data: () => ({
    lessonHeaders: [
      { text: 'Name', value: 'title' },
      { text: 'Objective', value: 'learningObjective' },
      { text: 'Last Updated At', value: 'updatedAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    moduleIntroductionHeaders: [
      { text: 'Type', value: 'contentType' },
      { text: 'Title', value: 'title' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    moduleHeaderMediaHeaders: [
      { text: 'Type', value: 'contentType' },
      { text: 'Title', value: 'title' },
      { text: 'Content', value: 'content' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    moduleId() {
      return this.$route.params.moduleId;
    },
    ContentType() {
      return ContentType;
    },
    defaultModule() {
      return {
        id: this.module?.id,
        title: this.module?.title,
        cardTitleColour: this.module?.cardTitleColour,
        cardSubtextColour: this.module?.cardSubtextColour,
        cardBackgroundColour: this.module?.cardBackgroundColour,
        progressFillColour: this.module?.progressFillColour,
        headerMedia: this.module?.headerMedia,
        moduleIntroduction: this.module?.moduleIntroduction,
        visible: this.module?.visible,
        disabled: this.module?.disabled,
        referenceId: this.module?.referenceId,
        version: this.module?.version
      };
    }
  },
  apollo: {
    module: {
      query: Module,
      variables() {
        return {
          id: this.moduleId
        };
      },
      skip() {
        return !this.moduleId;
      }
    },
    courseContent: {
      query: CourseContent,
      variables() {
        return { id: this.courseContentId };
      },
      skip() {
        return !this.courseContentId;
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async deleteLesson(id) {
      try {
        await this.$apollo.mutate({
          mutation: DeleteLessonFromModule,
          variables: {
            moduleId: this.module?.id,
            lessonId: id
          }
        });
      } catch (error) {
        this.$toast.error(
          error.graphQLErrors.map(x => x.message).join(', ')
        );
      }
      await this.$apollo.queries.module.refetch();
    },
    async addSelectedLessons(lessonIds) {
      try {
        for (let i = 0; i < lessonIds.length; i++) {
          await this.$apollo.mutate({
            mutation: AddLessonToModule,
            variables: {
              moduleId: this.module?.id,
              lessonId: lessonIds[i]
            }
          });
        }
      } catch (error) {
        this.$toast.error(
          error.graphQLErrors.map(x => x.message).join(', ')
        );
      }

      await this.$apollo.queries.module.refetch();
    },
    async updateModule(module) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateModule,
          variables: {
            module: module
          }
        });

        await this.$apollo.queries.module.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async saveHeaderMedia(content) {
      await this.updateModule({
        ...this.defaultModule,
        headerMedia: JSON.stringify(content)
      });
    },
    async createContent(content) {
      let existingModuleIntroduction = [
        ...this.module.moduleIntroduction
      ];
      existingModuleIntroduction.push(JSON.stringify(content));

      await this.updateModule({
        ...this.defaultModule,
        moduleIntroduction: existingModuleIntroduction
      });
    },
    async updateContent(content) {
      await this.updateModule({
        ...this.defaultModule,
        moduleIntroduction: this.module.moduleIntroduction.map(
          introContent =>
            JSON.parse(introContent).id === content.id
              ? JSON.stringify(content)
              : introContent
        )
      });
    },
    async deleteModuleIntroduction(id) {
      let existingModuleIntroduction = [
        ...this.module.moduleIntroduction
      ]
        .map(introContent => JSON.parse(introContent))
        .filter(introContent => introContent.id !== id)
        .map(introContent => JSON.stringify(introContent));

      await this.updateModule({
        ...this.defaultModule,
        moduleIntroduction: existingModuleIntroduction
      });
    },
    async deleteModuleHeaderMedia() {
      await this.updateModule({
        ...this.defaultModule,
        headerMedia: null
      });
    },
    async updateTitle(title) {
      await this.updateModule({
        ...this.defaultModule,
        title: title
      });
    },
    async updateCardTitleColour(cardTitleColour) {
      await this.updateModule({
        ...this.defaultModule,
        cardTitleColour: cardTitleColour
      });
    },
    async updateCardSubtextColour(cardSubtextColour) {
      await this.updateModule({
        ...this.defaultModule,
        cardSubtextColour: cardSubtextColour
      });
    },
    async updateCardBackgroundColour(cardBackgroundColour) {
      await this.updateModule({
        ...this.defaultModule,
        cardBackgroundColour: cardBackgroundColour
      });
    },
    async updateProgressFillColour(progressFillColour) {
      await this.updateModule({
        ...this.defaultModule,
        progressFillColour: progressFillColour
      });
    },
    async updateVisible(visible) {
      await this.updateModule({
        ...this.defaultModule,
        visible: visible
      });
    }
  }
};
</script>
