<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>{{ lesson?.title }}</h1>

          <v-row no-gutters align="center">
            <v-col cols="auto">
              <breadcrumbs :items="['Lessons', lesson?.title]" />
            </v-col>
            <v-col cols="auto">
              <v-chip
                small
                color="primary"
                class="font-weight-bold text-caption"
                >v{{ lesson?.version }}</v-chip
              >
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-row align="center" class="mr-0">
            <lesson-timeline-modal
              :lesson-reference-id="lesson?.completionReferenceId"
              :current-version="lesson?.version"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="text--primary mb-3 font-weight-bold text-h6">
        Lesson Configuration
      </div>
      <div class="rounded-lg white px-5 py-1 text--primary">
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Lesson Title
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :readonly="!courseContentId"
              :current-value="lesson?.title"
              @update-value="updateTitle"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Learning Objective
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :readonly="!courseContentId"
              :current-value="lesson?.learningObjective"
              @update-value="updateLearningObjective"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Search Keywords
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :readonly="!courseContentId"
              :current-value="lesson?.searchKeywords"
              @update-value="updateSearchKeywords"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-3">
          <v-col cols="auto" class="mr-auto title-column">
            Visible
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-switch
              :disabled="!courseContentId || isFrozenContent"
              :input-value="lesson?.visible"
              @change="updateVisible"
              class="mt-0"
              hide-details
              inset
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Learning Minutes
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <edit-field
              :readonly="!courseContentId"
              :current-value="lesson?.learningMinutes"
              @update-value="saveLearningMinutes"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Last Updated At
          </v-col>
          <v-col cols="auto" class="ml-auto">
            {{ formatDate(lesson?.updatedAt) }}
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Completion Tracking Between Lessons
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <lesson-selector
            v-if="lesson?.id === lesson?.completionReferenceId"
            :selected-lessons="lesson?.id ? [lesson?.id] : []"
            single-select
            @add-selected-lessons="updateCompletionReference"
          />
          <v-btn
            v-else
            color="error"
            small
            @click="updateCompletionReference([])"
          >
            <v-icon>mdi-delete-outline</v-icon>Remove</v-btn
          >
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            lesson?.completionReferenceLessons.filter(
              l => l.id !== lesson.id
            )
          "
          :headers="lessonCompletionHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No lesson completions are counted towards this lesson"
          loading-text="Loading..."
          item-key="id"
          @click:row="
            item =>
              $router.push(
                courseContentId
                  ? `/course-content/${courseContentId}/lessons/${item.id}`
                  : `/lessons/${item.id}`
              )
          "
        >
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-btn
              icon
              @click="
                $router.push(
                  courseContentId
                    ? `/course-content/${courseContentId}/lessons/${item.id}`
                    : `/lessons/${item.id}`
                )
              "
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Lesson - Card Image
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <create-content-modal
            :required-content-type="[ContentType.IMAGE]"
            :disabled="!!lesson?.lessonCardImage"
            @create-content="saveCardImage"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            lesson?.lessonCardImage
              ? [JSON.parse(lesson?.lessonCardImage)]
              : []
          "
          :headers="lessonHeaderMediaHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No lesson card image"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.content`]="{ item }">
            <bucket-object-format :bucket-object-id="item.content" />
          </template>
          <template v-slot:[`item.contentType`]="{ item }">
            <v-icon v-if="item.contentType == ContentType.IMAGE"
              >mdi-image-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.VIDEO"
              >mdi-video-outline</v-icon
            >
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-row justify="end" align="center">
              <edit-content-modal
                :required-content-type="[ContentType.IMAGE]"
                :current-content="item"
                @save-content="saveCardImage"
              />
              <delete-button
                v-if="courseContentId"
                @delete="deleteLessonCardImage"
              />
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Lesson Introduction - Header Media
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <create-content-modal
            :required-content-type="[
              ContentType.IMAGE,
              ContentType.VIDEO
            ]"
            :disabled="!!lesson?.headerMedia"
            @create-content="saveHeaderMedia"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            lesson?.headerMedia
              ? [JSON.parse(lesson?.headerMedia)]
              : []
          "
          :headers="lessonHeaderMediaHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No lesson introduction header media"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.content`]="{ item }">
            <bucket-object-format :bucket-object-id="item.content" />
          </template>
          <template v-slot:[`item.contentType`]="{ item }">
            <v-icon v-if="item.contentType == ContentType.IMAGE"
              >mdi-image-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.VIDEO"
              >mdi-video-outline</v-icon
            >
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-row justify="end" align="center">
              <edit-content-modal
                :required-content-type="[
                  ContentType.IMAGE,
                  ContentType.VIDEO
                ]"
                :current-content="item"
                @save-content="saveHeaderMedia"
              />
              <delete-button
                v-if="courseContentId"
                @delete="deleteLessonHeaderMedia"
              />
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Lesson Introduction - Content
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <create-content-modal @create-content="createContent" />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            lesson?.lessonIntroduction?.map(fb => JSON.parse(fb)) ??
              []
          "
          :headers="lessonIntroductionHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No lesson introduction content"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.contentType`]="{ item }">
            <v-icon v-if="item.contentType == ContentType.TEXT"
              >mdi-text-shadow</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.IMAGE"
              >mdi-image-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.VIDEO"
              >mdi-video-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.PDF"
              >mdi-file-pdf-outline</v-icon
            >
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-row justify="end" align="center">
              <edit-content-modal
                :current-content="item"
                @save-content="updateLessonIntroduction"
              />
              <delete-button
                v-if="courseContentId"
                @delete="deleteLessonIntroduction(item.id)"
              />
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Learning Material - Header Media
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <create-content-modal
            :required-content-type="[
              ContentType.IMAGE,
              ContentType.VIDEO
            ]"
            :disabled="!!lesson?.learningHeaderMedia"
            @create-content="saveLearningHeaderMedia"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            lesson?.learningHeaderMedia
              ? [JSON.parse(lesson?.learningHeaderMedia)]
              : []
          "
          :headers="lessonHeaderMediaHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No learning header media"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.content`]="{ item }">
            <bucket-object-format :bucket-object-id="item.content" />
          </template>
          <template v-slot:[`item.contentType`]="{ item }">
            <v-icon v-if="item.contentType == ContentType.IMAGE"
              >mdi-image-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.VIDEO"
              >mdi-video-outline</v-icon
            >
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-row justify="end" align="center">
              <edit-content-modal
                :required-content-type="[
                  ContentType.IMAGE,
                  ContentType.VIDEO
                ]"
                :current-content="item"
                @save-content="saveLearningHeaderMedia"
              />
              <delete-button
                v-if="courseContentId"
                @delete="deleteLearningHeaderMedia"
              />
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Learning Material - Introduction
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <create-content-modal
            @create-content="createLearningIntroduction"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="
            lesson?.learningIntroduction?.map(fb => JSON.parse(fb)) ??
              []
          "
          :headers="lessonIntroductionHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No learning material introduction content"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.contentType`]="{ item }">
            <v-icon v-if="item.contentType == ContentType.TEXT"
              >mdi-text-shadow</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.IMAGE"
              >mdi-image-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.VIDEO"
              >mdi-video-outline</v-icon
            >
            <v-icon v-if="item.contentType == ContentType.PDF"
              >mdi-file-pdf-outline</v-icon
            >
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <v-row justify="end" align="center">
              <edit-content-modal
                :current-content="item"
                @save-content="updateLearningIntroduction"
              />
              <delete-button
                v-if="courseContentId"
                @delete="() => deleteLearningIntroduction(item.id)"
              />
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Feedback
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <create-lesson-feedback-modal
            :lesson-id="lesson?.id"
            :disabled="isFrozenContent"
            @add-threshold="addThreshold"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="lesson?.feedback?.map(fb => JSON.parse(fb)) ?? []"
          :headers="feedbackHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="Feedback not set up for this lesson"
          loading-text="Loading..."
          item-key="id"
        >
          <template v-slot:[`item.editColumn`]="{ item }">
            <delete-button
              v-if="courseContentId"
              @delete="() => deleteThreshold(item.id)"
            />
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Questions
        </v-col>

        <v-col v-if="courseContentId && !isFrozenContent" cols="auto">
          <create-question-modal
            :disabled="isFrozenContent"
            @refetch="$apollo.queries.lesson.refetch()"
          />
        </v-col>
      </v-row>
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="lesson?.questions ?? []"
          :headers="questionHeaders"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="There are no questions for this lesson"
          item-key="id"
          @click:row="
            item =>
              $router.push(
                courseContentId
                  ? `/course-content/${courseContentId}/lessons/${lesson?.id}/question/${item.id}`
                  : `/lessons/${lesson?.id}/question/${item.id}`
              )
          "
        >
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>
          <template v-slot:[`item.questionType`]="{ item }">
            <span class="text-capitalize">{{
              item.questionType.toLowerCase()
            }}</span>
          </template>
          <template v-slot:[`item.variations`]="{ item }">
            {{ item.variations.length }}
          </template>
          <template v-slot:[`item.editColumn`]="{ item }">
            <delete-button
              v-if="courseContentId"
              @delete="() => deleteQuestion(item.id)"
            />
            <v-btn
              v-if="courseContentId"
              icon
              @click="
                $router.push(
                  `/course-content/${courseContentId}/lessons/${lesson?.id}/question/${item.id}`
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="!courseContentId"
              icon
              @click="
                $router.push(
                  `/lessons/${lesson?.id}/question/${item.id}`
                )
              "
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Lesson,
  SaveLessonThresholds,
  UpdateLesson
} from '@/graphql/Lesson.gql';
import { CourseContent } from '@/graphql/CourseContent.gql';
import { DeleteQuestion } from '@/graphql/Question.gql';
import { formatDate } from '@/utils/date';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import CreateLessonFeedbackModal from '@/components/Lesson/CreateLessonFeedbackModal';
import CreateQuestionModal from '@/components/Question/CreateQuestionModal';
import CreateContentModal from '@/components/Common/CreateContentModal';
import ContentType from '@/enums/ContentType';
import BucketObjectFormat from '@/components/Common/BucketObjectFormat';
import EditField from '@/components/Common/EditField';
import LessonSelector from '@/components/Lesson/LessonSelector';
import LessonTimelineModal from '@/components/Lesson/LessonTimelineModal';
import DeleteButton from '@/components/Common/DeleteButton.vue';
import EditContentModal from '@/components/Common/EditContentModal';

export default {
  name: 'LessonPage',
  components: {
    Breadcrumbs,
    CreateLessonFeedbackModal,
    CreateQuestionModal,
    CreateContentModal,
    BucketObjectFormat,
    EditField,
    LessonSelector,
    LessonTimelineModal,
    DeleteButton,
    EditContentModal
  },
  data: () => ({
    feedbackHeaders: [
      { text: 'Threshold', value: 'threshold' },
      { text: 'Header', value: 'header' },
      { text: 'Feedback', value: 'feedback' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    questionHeaders: [
      { text: 'Question', value: 'title' },
      { text: 'Type', value: 'questionType' },
      { text: 'Variations', value: 'variations' },
      { text: 'Last Updated At', value: 'updatedAt' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    lessonIntroductionHeaders: [
      { text: 'Type', value: 'contentType' },
      { text: 'Title', value: 'title' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    lessonHeaderMediaHeaders: [
      { text: 'Type', value: 'contentType' },
      { text: 'Title', value: 'title' },
      { text: 'Content', value: 'content' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    lessonCompletionHeaders: [
      { text: 'Lesson Title', value: 'title' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ]
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    lessonId() {
      return this.$route.params.lessonId;
    },
    ContentType() {
      return ContentType;
    },
    defaultLesson() {
      return {
        id: this.lesson?.id,
        title: this.lesson?.title,
        learningObjective: this.lesson?.learningObjective,
        searchKeywords: this.lesson?.searchKeywords,
        headerMedia: this.lesson?.headerMedia,
        feedback: this.lesson?.feedback,
        lessonIntroduction: this.lesson?.lessonIntroduction,
        visible: this.lesson?.visible,
        completionReferenceId: this.lesson?.completionReferenceId,
        learningHeaderMedia: this.lesson?.learningHeaderMedia,
        learningIntroduction: this.lesson?.learningIntroduction,
        version: this.lesson?.version,
        disabled: this.lesson?.disabled,
        learningMinutes: this.lesson?.learningMinutes
      };
    },
    isFrozenContent() {
      return (
        this.courseContent?.state === 'PUBLISHED' ||
        this.courseContent?.state === 'UNAVAILABLE' ||
        !this.courseContent
      );
    }
  },
  apollo: {
    lesson: {
      query: Lesson,
      variables() {
        return {
          id: this.lessonId
        };
      },
      skip() {
        return !this.lessonId;
      }
    },
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async deleteThreshold(thresholdId) {
      const existingThresholds = [...(this.lesson?.feedback ?? [])];

      await this.saveThresholds(
        existingThresholds.filter(threshold => {
          const parsedString = JSON.parse(threshold);

          return parsedString.id !== thresholdId;
        })
      );
    },
    async addThreshold(threshold) {
      const thresholdToAdd = JSON.stringify(threshold);
      const existingThresholds = [...(this.lesson?.feedback ?? [])];
      existingThresholds.push(thresholdToAdd);

      await this.saveThresholds(existingThresholds);
    },
    async saveThresholds(thresholds) {
      try {
        await this.$apollo.mutate({
          mutation: SaveLessonThresholds,
          variables: {
            lessonId: this.lessonId,
            thresholds: thresholds
          }
        });
        await this.$apollo.queries.lesson.refetch();
      } catch (error) {
        this.$toast.error(
          'Failed to save thresholds: ' +
            error.graphQLErrors.map(x => x.message).join(', ')
        );
      }
    },
    async deleteQuestion(id) {
      try {
        await this.$apollo.mutate({
          mutation: DeleteQuestion,
          variables: {
            id: id
          }
        });
        await this.$apollo.queries.lesson.refetch();
      } catch (error) {
        this.$toast.error(
          'Failed to delete question: ' +
            error.graphQLErrors.map(x => x.message).join(', ')
        );
      }
    },
    async updateLesson(lesson) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateLesson,
          variables: {
            lesson: lesson
          }
        });

        await this.$apollo.queries.lesson.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async saveHeaderMedia(content) {
      await this.updateLesson({
        ...this.defaultLesson,
        headerMedia: JSON.stringify(content)
      });
    },
    async saveLearningMinutes(learningMinutes) {
      await this.updateLesson({
        ...this.defaultLesson,
        learningMinutes: learningMinutes
      });
    },
    async saveLearningHeaderMedia(content) {
      await this.updateLesson({
        ...this.defaultLesson,
        learningHeaderMedia: JSON.stringify(content)
      });
    },
    async saveCardImage(content) {
      await this.updateLesson({
        ...this.defaultLesson,
        lessonCardImage: JSON.stringify(content)
      });
    },
    async createContent(content) {
      let existingLessonIntroduction = [
        ...this.lesson.lessonIntroduction
      ];
      existingLessonIntroduction.push(JSON.stringify(content));

      await this.updateLesson({
        ...this.defaultLesson,
        lessonIntroduction: existingLessonIntroduction
      });
    },
    async updateLessonIntroduction(content) {
      await this.updateLesson({
        ...this.defaultLesson,
        lessonIntroduction: this.lesson.lessonIntroduction.map(
          introContent =>
            JSON.parse(introContent).id === content.id
              ? JSON.stringify(content)
              : introContent
        )
      });
    },
    async deleteLessonIntroduction(id) {
      let existingLessonIntroduction = [
        ...this.lesson.lessonIntroduction
      ]
        .map(introContent => JSON.parse(introContent))
        .filter(introContent => introContent.id !== id)
        .map(introContent => JSON.stringify(introContent));

      await this.updateLesson({
        ...this.defaultLesson,
        lessonIntroduction: existingLessonIntroduction
      });
    },
    async createLearningIntroduction(content) {
      let existingLearningIntroduction = [
        ...this.lesson.learningIntroduction
      ];
      existingLearningIntroduction.push(JSON.stringify(content));

      await this.updateLesson({
        ...this.defaultLesson,
        learningIntroduction: existingLearningIntroduction
      });
    },
    async updateLearningIntroduction(content) {
      await this.updateLesson({
        ...this.defaultLesson,
        learningIntroduction: this.lesson.learningIntroduction.map(
          introContent =>
            JSON.parse(introContent).id === content.id
              ? JSON.stringify(content)
              : introContent
        )
      });
    },
    async deleteLearningIntroduction(id) {
      let existingLearningIntroduction = [
        ...this.lesson.learningIntroduction
      ]
        .map(introContent => JSON.parse(introContent))
        .filter(introContent => introContent.id !== id)
        .map(introContent => JSON.stringify(introContent));

      await this.updateLesson({
        ...this.defaultLesson,
        learningIntroduction: existingLearningIntroduction
      });
    },
    async deleteLessonHeaderMedia() {
      await this.updateLesson({
        ...this.defaultLesson,
        headerMedia: null
      });
    },
    async deleteLearningHeaderMedia() {
      await this.updateLesson({
        ...this.defaultLesson,
        learningHeaderMedia: null
      });
    },
    async deleteLessonCardImage() {
      await this.updateLesson({
        ...this.defaultLesson,
        lessonCardImage: null
      });
    },
    async updateTitle(title) {
      await this.updateLesson({
        ...this.defaultLesson,
        title: title
      });
    },
    async updateLearningObjective(learningObjective) {
      await this.updateLesson({
        ...this.defaultLesson,
        learningObjective: learningObjective
      });
    },
    async updateSearchKeywords(searchKeywords) {
      await this.updateLesson({
        ...this.defaultLesson,
        searchKeywords: searchKeywords
      });
    },
    async updateCompletionReference(completionReferenceId) {
      await this.updateLesson({
        ...this.defaultLesson,
        completionReferenceId:
          completionReferenceId.length > 0
            ? completionReferenceId[0]
            : this.defaultLesson.id
      });
    },
    async updateVisible(visible) {
      await this.updateLesson({
        ...this.defaultLesson,
        visible: visible
      });
    }
  }
};
</script>
