<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col class="ml-n3 mr-1" cols="auto">
              <v-btn color="#00000060" icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <h1>
                Learner Data:
                {{
                  [
                    userAccount?.firstName,
                    userAccount?.lastName
                  ].join(' ')
                }}
              </h1>
            </v-col>
          </v-row>

          <breadcrumbs :items="breadcrumbItems" />
        </v-col>
      </v-row>
    </v-container>

    <div class="mt-8">
      <div class="rounded-lg white px-5 text--primary">
        <v-data-table
          :items="formattedLessonCompletionsForUser"
          :headers="summaryOfCourseCompletionHeaders"
          :loading="$apollo.queries.lessonCompletionsForUser.loading"
          class="dark--text rounded-b-lg rounded-t-0"
          no-data-text="No completions recorded for this user"
          loading-text="Loading – this can take up to 60 seconds..."
          item-key="id"
        >
          <template v-slot:[`item.courseAvailability`]="{ item }">
            <span
              class="text-capitalize"
              :style="
                `color: ${getStateColour(item.courseAvailability)}`
              "
            >
              {{
                item.courseAvailability
                  .replaceAll('_', ' ')
                  .toLowerCase()
              }}
            </span>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import { formatDate } from '@/utils/date';
import { LessonCompletionsForUser } from '@/graphql/LessonCompletion.gql';
import { UserAccount } from '@/graphql/UserAccount.gql';
import { Organisation } from '@/graphql/Organisation.gql';
import { Site } from '@/graphql/Site.gql';
import { getStateColour } from '@/utils/courseContent';

export default {
  name: 'UserCompletionData',
  components: { Breadcrumbs },
  data: () => ({
    summaryOfCourseCompletionHeaders: [
      { text: 'Course', value: 'course' },
      { text: 'Module', value: 'module' },
      { text: 'Lesson', value: 'lesson' },
      { text: 'Is Available', value: 'courseAvailability' },
      { text: 'Last Score', value: 'lastScore' },
      { text: 'Last Attempted At', value: 'lastAttemptedAt' }
    ]
  }),
  computed: {
    userAccountId() {
      return this.$route.params.userAccountId;
    },
    organisationId() {
      return this.$route.params.organisationId;
    },
    siteId() {
      return this.$route.params.siteId;
    },
    breadcrumbItems() {
      let breadcrumbs = [];

      if (this.organisationId || this.siteId) {
        breadcrumbs.push('Organisation');
        breadcrumbs.push(this.organisation?.companyName);
      }

      if (this.siteId) {
        breadcrumbs.push('Site');
        breadcrumbs.push(this.site?.name);
      }

      breadcrumbs.push(this.isStaffAccount ? 'Staff' : 'Learner');

      breadcrumbs.push(
        [
          this.userAccount?.firstName,
          this.userAccount?.lastName
        ].join(' ')
      );

      return [...breadcrumbs, 'Completion Data'];
    },
    formattedLessonCompletionsForUser() {
      return (this.lessonCompletionsForUser ?? []).map(
        lessonCompletion => {
          const module = lessonCompletion.courseCompleted.modules.find(
            module =>
              module.lessons.some(
                lesson =>
                  lesson.id === lessonCompletion.lessonCompleted.id
              )
          );

          return {
            course: lessonCompletion.courseCompleted.title,
            module: module.title,
            lesson: lessonCompletion.lessonCompleted.title,
            lastScore: `${Number(
              (lessonCompletion.correctQuestions /
                lessonCompletion.totalQuestions) *
                100
            ).toFixed(0)}%`,
            lastAttemptedAt: this.formatDate(
              lessonCompletion.updatedAt
            ),
            courseAvailability:
              lessonCompletion.courseContentCompleted.state
          };
        }
      );
    }
  },
  apollo: {
    userAccount: {
      query: UserAccount,
      variables() {
        return {
          id: this.userAccountId
        };
      }
    },
    lessonCompletionsForUser: {
      query: LessonCompletionsForUser,
      variables() {
        return {
          userAccountId: this.userAccountId
        };
      }
    },
    organisation: {
      query: Organisation,
      variables() {
        return {
          id: this.organisationId
        };
      },
      skip() {
        return !this.organisationId;
      }
    },
    site: {
      query: Site,
      variables() {
        return {
          id: this.siteId
        };
      },
      skip() {
        return !this.siteId;
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    getStateColour(state) {
      return getStateColour(state);
    }
  }
};
</script>
