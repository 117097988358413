<template>
  <v-row no-gutters align="center">
    <v-col>
      <div
        class="text--secondary text-caption font-weight-light mt-n1"
        style="font-size: 10px !important;"
      >
        Version: {{ version }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VersionDisplay',
  data: () => ({
    version: ''
  }),
  async mounted() {
    this.version = await this.getVersion();
  },
  methods: {
    async getVersion() {
      const response = await axios.get(
        `${process.env.VUE_APP_SERVER_URL}`
      );
      return response.data.version;
    }
  }
};
</script>
