<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          small
        >
          <v-icon>mdi-plus</v-icon>Content</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Content
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createContentForm">
            <v-row>
              <v-col>
                <v-label>Title</v-label>
                <v-text-field
                  v-model="title"
                  :rules="rules.requiredField"
                  label="Title"
                  single-line
                  outlined
                  dense
                  required
                />
              </v-col>
              <v-col>
                <v-label>Content Type</v-label>
                <v-select
                  v-model="contentType"
                  :items="contentTypeItems"
                  :rules="rules.requiredField"
                  label="Content Type"
                  single-line
                  outlined
                  dense
                  required
                />
              </v-col>
            </v-row>

            <tiptap-vuetify
              v-if="contentType == ContentType.TEXT"
              v-model="textContent"
              :extensions="extensions"
            />

            <div
              v-if="
                contentType == ContentType.IMAGE ||
                  contentType == ContentType.VIDEO ||
                  contentType == ContentType.PDF
              "
            >
              <v-label class="text-capitalize">{{
                contentType.toString().toLowerCase()
              }}</v-label>
              <v-file-input
                ref="fileInput"
                v-model="input.file"
                @change="validateFile"
                single-line
                outlined
                dense
                required
              />

              <v-label v-if="input.file" class="mt-3 text-capitalize"
                >{{
                  contentType.toString().toLowerCase()
                }}
                Name</v-label
              >
              <v-text-field
                v-if="input.file"
                v-model="input.name"
                label="Name"
                single-line
                outlined
                dense
                required
              />
            </div>
          </v-form>
        </v-card-text>

        <v-progress-linear
          indeterminate
          v-if="isLoading"
        ></v-progress-linear>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            :disabled="isLoading"
          >
            Cancel
          </v-btn>
          <v-btn
            color="secondary"
            class="primary--text"
            @click="createContent"
            :disabled="isLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import ContentType, { contentTypeItems } from '@/enums/ContentType';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  History
} from 'tiptap-vuetify';
import axios from 'axios';
import moment from 'moment';
import {
  CreateBucketObjectWithName,
  RequestUploadUrl
} from '@/graphql/BucketObject.gql';

export default {
  name: 'CreateContentModal',
  components: { TiptapVuetify },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    requiredContentType: {
      default: () => []
    }
  },
  data: () => ({
    extensions: [
      History,
      Underline,
      Italic,
      ListItem, // if you need to use a list (BulletList, OrderedList)
      BulletList,
      OrderedList,
      [
        Heading,
        {
          // Options that fall into the tiptap's extension
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      HardBreak // line break on Shift + Ctrl + Enter
    ],
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    title: '',
    contentType: 'TEXT',
    textContent: '',
    input: {},
    isLoading: false
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$refs.createContentForm.reset();
      } else {
        this.resetForm();

        if (this.requiredContentType.length > 0) {
          this.contentType = this.requiredContentType[0];
        }
      }
    }
  },
  computed: {
    lessonId() {
      return this.$route.params.lessonId;
    },
    ContentType() {
      return ContentType;
    },
    contentTypeItems() {
      if (this.requiredContentType.length === 0)
        return contentTypeItems;

      return contentTypeItems.filter(contentTypeItem =>
        this.requiredContentType.includes(contentTypeItem.value)
      );
    }
  },
  methods: {
    resetForm() {
      this.title = '';
      this.contentType = 'TEXT';
      this.input = {};
      this.textContent = '';
    },
    async createContent() {
      if (this.$refs.createContentForm.validate()) {
        this.isLoading = true;
        try {
          if (
            this.contentType === this.ContentType.IMAGE ||
            this.contentType === this.ContentType.VIDEO ||
            this.contentType === this.ContentType.PDF
          ) {
            await this.createFileContent();
            return;
          }

          this.$emit('create-content', {
            id: uuidv4(),
            title: this.title,
            contentType: this.contentType,
            content: this.textContent
          });
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.isLoading = false;
        }
      }
    },
    async validateFile() {
      this.input.name = this.input.file.name;
    },
    async createFileContent() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: CreateBucketObjectWithName,
          variables: {
            bucketObject: {
              objectId: `/lesson/${
                this.lessonId
              }/${moment().unix()}-${this.input.name.replaceAll(
                '/',
                '-'
              )}`,
              fileType: this.input.file.type
            }
          }
        });
        const media = response.data.createBucketObjectWithName;

        const uploadUrlResponse = await this.$apollo.mutate({
          mutation: RequestUploadUrl,
          variables: {
            bucketObjectId: media.id
          }
        });
        const uploadUrl = uploadUrlResponse.data.requestUploadUrl;

        try {
          await axios.put(uploadUrl, this.input.file, {
            headers: { 'Content-Type': this.input.file.type }
          });

          this.$emit('create-content', {
            id: uuidv4(),
            title: this.title,
            contentType: this.contentType,
            content: media.id
          });

          this.dialog = false;
        } catch (error) {
          console.log(error.message);
          this.$toast.error('Failed to upload file.');
        } finally {
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error.message);
        this.$toast.error('Failed to upload file.');
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
