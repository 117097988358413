<template>
  <div v-if="loading" class="px-4 pt-8 d-flex align-center">
    <v-progress-circular indeterminate color="primary" />
  </div>
  <div v-else class="px-4 pt-8 d-flex flex-column align-center">
    <v-col cols="10" class="flex-grow-1">
      <v-row no-gutters justify="center" class="dark-blue-text">
        <h2>You're almost there!</h2>
      </v-row>

      <v-row
        no-gutters
        justify="center"
        class="font-weight-light dark-blue-text text-center mt-4"
      >
        <p>
          Your password has been reset.
        </p>
      </v-row>

      <v-row
        no-gutters
        justify="center"
        class="font-weight-light dark-blue-text text-center mt-4"
      >
        <p>
          {{
            isStaff
              ? 'Log in with your email address and password in on the Ask Annie login page now.'
              : 'Log in with your email address and password in the Ask Annie mobile app on your iOS or Android device now.'
          }}
        </p>
      </v-row>

      <v-row class="mt-6" justify="center" v-if="!isStaff">
        <p class="font-weight-bold text-center">
          Download Ask Annie now!
        </p>
      </v-row>

      <v-row class="mt-6" justify="center">
        <v-btn
          v-if="isStaff"
          color="primary"
          large
          class="login-button"
          @click="$router.push('/login')"
        >
          Back to login
        </v-btn>

        <a
          v-if="!isStaff"
          class="mr-4"
          href="https://apps.apple.com/in/app/ask-annie/id1541576444"
          target="_blank"
        >
          <img
            src="../../images/AppStoreButton.svg"
            alt="App Store Button"
            height="47"
          />
        </a>
        <a
          v-if="!isStaff"
          href="https://play.google.com/store/apps/details?id=au.org.dementia"
          target="_blank"
        >
          <img
            src="../../images/GooglePlayButton.svg"
            alt="Google Play Button"
            height="47"
          />
        </a>
      </v-row>
    </v-col>

    <v-row class="mt-auto pb-6 flex-grow-0" justify="center">
      <span class="font-weight-light dark-blue-text">
        Need some help?
        <a
          href="https://www.dementia.org.au/professionals/professional-development-and-training/ask-annie/ask-annie-support"
          class="toggle-link"
          target="_blank"
        >
          Contact us
        </a>
      </span>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ResetPasswordComplete',
  props: {
    email: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isStaff: false,
    loading: true
  }),
  async mounted() {
    try {
      this.loading = true;

      const baseUrl =
        process.env.VUE_APP_SERVER_URL ||
        'https://prod-server-682260008929.australia-southeast2.run.app';
      const response = await fetch(`${baseUrl}/is-staff`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: this.email })
      });
      const data = await response.json();
      this.isStaff = data.isStaff;
    } catch (error) {
      console.error('Error checking staff status:', error);
      this.isStaff = false;
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-link {
  color: #113247;
  text-decoration: underline;
}

.dark-blue-text {
  color: #113247;
}

.login-button {
  width: 100%;
  max-width: 200px;
}
</style>
