<template>
  <div v-if="editing">
    <v-row align="center" no-gutters>
      <v-col cols="auto">
        <v-text-field
          v-if="type === 'text'"
          v-model="value"
          single-line
          outlined
          dense
          hide-details
          autofocus
        />
        <v-select
          v-else-if="type === 'select'"
          v-model="value"
          :items="selectItems"
          single-line
          outlined
          dense
          hide-details
        />
        <tiptap-vuetify
          v-else-if="type === 'richtext'"
          class="mb-4"
          v-model="value"
          :extensions="extensions"
        />
        <v-file-input
          v-else-if="type === 'file'"
          ref="fileInput"
          v-model="file.file"
          @change="validateFile"
          style="min-width: 200px;"
          single-line
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="auto">
        <v-btn class="ml-2" icon @click="updateValue">
          <v-icon>mdi-check-bold</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row
      align="center"
      no-gutters
      :class="
        `${
          courseContent?.state == 'PUBLISHED' ||
          readonly ||
          isFrozenContent
            ? 'py-2'
            : ''
        }`
      "
    >
      <v-col v-if="type == 'richtext'"
        ><span v-html="currentValue"></span
      ></v-col>
      <v-col
        v-else
        cols="auto"
        :class="
          `${textClass} ${type === 'select' ? 'text-capitalize' : ''}`
        "
      >
        <slot />
        {{
          displayValue
            ? displayValue
            : currentValue
            ? currentValue
            : '-'
        }}
      </v-col>
      <v-col v-if="!readonly && !isFrozenContent" cols="auto">
        <v-btn
          class="ml-2"
          :disabled="readonly || isFrozenContent"
          icon
          @click="startEditing"
        >
          <v-icon>{{ addIcon ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { CourseContent } from '@/graphql/CourseContent.gql';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  History
} from 'tiptap-vuetify';

export default {
  name: 'EditField',
  components: { TiptapVuetify },
  props: {
    textClass: {
      type: String,
      default: ''
    },
    displayValue: {
      type: String,
      default: undefined
    },
    currentValue: {
      default: ''
    },
    readonly: {
      type: Boolean
    },
    selectItems: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'text'
    },
    addIcon: {
      type: Boolean
    }
  },
  data: () => ({
    editing: false,
    value: '',
    file: {},
    extensions: [
      History,
      Underline,
      Italic,
      ListItem, // if you need to use a list (BulletList, OrderedList)
      BulletList,
      OrderedList,
      [
        Heading,
        {
          // Options that fall into the tiptap's extension
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      HardBreak // line break on Shift + Ctrl + Enter
    ]
  }),
  computed: {
    isFrozenContent() {
      return (
        this.courseContent?.state === 'PUBLISHED' ||
        this.courseContent?.state === 'UNAVAILABLE'
      );
    }
  },
  apollo: {
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      },
      skip() {
        return !this.$route.params.courseContentId;
      }
    }
  },
  methods: {
    startEditing() {
      this.value = this.currentValue;
      this.editing = true;
    },
    validateFile() {
      this.input.name = this.input.file.name;
    },
    updateValue() {
      this.$emit(
        'update-value',
        this.type === 'file' ? this.file : this.value
      );
      this.editing = false;
    }
  }
};
</script>

<style lang="scss">
.col > div > p {
  margin-bottom: 0 !important;
}
</style>
