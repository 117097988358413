<template>
  <v-col cols="10">
    <v-form
      ref="form"
      class="px-4 pt-8"
      @input="validateForm"
      @submit.prevent="handleSubmit"
    >
      <v-row no-gutters justify="center" class="dark-blue-text">
        <h2>
          {{ forgotPasswordToggled ? 'Reset Password' : 'Login' }}
        </h2>
      </v-row>
      <v-row v-if="!forgotPasswordToggled" no-gutters justify="center" class="font-weight-light dark-blue-text">
        <p>Welcome back to Ask Annie.</p>
      </v-row>
      <v-text-field
        v-model="email"
        outlined
        placeholder="Email"
        :rules="emailRules"
        hide-details="false"
        class="mt-3 mb-4"
        :disabled="loading"
      />
      <v-text-field
        v-if="!forgotPasswordToggled"
        v-model="password"
        type="password"
        outlined
        :rules="passwordRules"
        hide-details="false"
        placeholder="Password"
        :disabled="loading"
      />

      <v-row v-if="message" class="mt-4" justify="center">
        <span :class="messageClass">{{ message }}</span>
      </v-row>

      <v-row class="mt-6" justify="center">
        <v-btn
          color="primary"
          large
          class="login-button"
          type="submit"
          :loading="loading"
          :disabled="loading"
        >
          {{ forgotPasswordToggled ? 'Reset Password' : 'Login' }}
        </v-btn>
      </v-row>

      <v-row class="mt-6" justify="center">
        <a
          href="javascript:void(0)"
          class="toggle-link font-weight-light"
          @click="toggleForgotPassword"
        >
          {{ forgotPasswordToggled ? 'Back to login' : 'Forgot password' }}
        </a>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import { auth } from '@/main';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';

export default {
  name: 'LoginForm',
  data: () => ({
    email: '',
    password: '',
    message: '',
    forgotPasswordToggled: false,
    emailRules: [
      v => !!v || 'Email is required',
      // Highly permissive email validation, since it's just for login not account creation anyway.
      // I made it up. It lets anything other than white space before the @, and then a-zA-Z0-9 after the @
      // dot seperated.
      v => /^([^\s]+)(@)(\w[\w.]+\w)$/gim.test(v) || 'Email must be valid'
    ],
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    valid: true,
    loading: false
  }),
  computed: {
    messageClass() {
      const color = this.forgotPasswordToggled ? 'success--text' : 'error--text';
      return `${color} font-weight-light`;
    }
  },
  methods: {
    validateForm() {
      if (this.message && this.$refs.form.validate()) {
        this.message = '';
      }
    },
    async handleLogin() {
      if (!this.$refs.form.validate()) {
        this.message = 'Please fill in all required fields correctly';
        return;
      }
      this.message = '';
      this.loading = true;
      try {
        await this.login();
      } finally {
        this.loading = false;
      }
    },
    async handleResetPassword() {
      if (!this.$refs.form.validate()) {
        this.message = 'Please enter a valid email address';
        return;
      }
      this.message = '';
      this.loading = true;
      try {
        await this.resetPassword();
      } finally {
        this.loading = false;
      }
    },
    login() {
      return signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => window.open('/', '_self'))
        .catch(() => (this.message = 'Email or password is incorrect. Please try again'));
    },
    resetPassword() {
      return sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.$emit('reset-email-sent', this.email);
        });
    },
    toggleForgotPassword() {
      this.forgotPasswordToggled = !this.forgotPasswordToggled;
      this.email = '';
      this.password = '';
      this.message = '';
    },
    handleSubmit() {
      if (this.forgotPasswordToggled) {
        this.handleResetPassword();
      } else {
        this.handleLogin();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-link {
  color: #113247;
  text-decoration: none;
  font-size: 15px;
}

.dark-blue-text {
  color: #113247;
}

.login-button {
  width: 100%;
  max-width: 200px;
}
</style>
