<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          <v-icon class="mr-2">mdi-plus</v-icon>
          New Node
        </v-btn>
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Node
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="newNodeForm">
            <v-label>Label</v-label>
            <v-text-field
              v-model="label"
              :rules="rules.requiredField"
              label="Label"
              single-line
              outlined
              dense
              required
            />

            <v-label>Node Type</v-label>
            <v-select
              v-model="branchingNodeType"
              :rules="rules.requiredField"
              :items="branchingNodeTypeItems"
              :disabled="isFirstNode"
              label="Node Type"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" class="primary--text" @click="addQuestionVariation">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { branchingNodeTypeItems } from '@/enums/BranchingNodeType';
import BranchingOutcome from '@/enums/BranchingOutcome';

export default {
  name: 'CreateBranchModal',
  props: {
    isFirstNode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    id: uuidv4(),
    branchingNodeType: 'QUESTION',
    label: ''
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.resetForm();
        this.$refs.newNodeForm.reset();
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    branchingNodeTypeItems() {
      return branchingNodeTypeItems;
    }
  },
  methods: {
    resetForm() {
      this.id = uuidv4();
      this.branchingNodeType = 'QUESTION';
      this.label = '';
    },
    async addQuestionVariation() {
      if (this.$refs.newNodeForm.validate()) {
        try {
          this.$emit('add-variation', {
            id: this.id,
            branchingNodeType: this.branchingNodeType,
            label: this.label,
            questions: [],
            outcome: BranchingOutcome.EXIT_QUESTION,
            feedbackHeading: '',
            feedbackMessage: '',
            images: [],
            videos: [],
            pdfs: []
          });
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        }
      }
    }
  }
};
</script>
