var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-3",
                          attrs: { color: "primary", small: _vm.dense }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-eye-outline")
                      ]),
                      _vm._v("Change Visibility")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" Change Visibility ")]
              ),
              _c("v-card-text", { staticClass: "py-2" }, [
                _c("div", [
                  _vm._v(
                    " Change who can see the changes made in this version (currently set to "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-capitalize",
                      style: `color: ${_vm.getStateColour(_vm.currentState)}`
                    },
                    [_vm._v(" " + _vm._s(_vm.currentState.toLowerCase()))]
                  ),
                  _vm._v("): ")
                ]),
                _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: [
                          { text: "Draft", value: "DRAFT" },
                          { text: "Testing", value: "TESTING" },
                          { text: "Published", value: "PUBLISHED" },
                          { text: "Unavailable", value: "UNAVAILABLE" }
                        ],
                        outlined: "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.newState,
                        callback: function($$v) {
                          _vm.newState = $$v
                        },
                        expression: "newState"
                      }
                    })
                  ],
                  1
                ),
                _vm.newState === "UNAVAILABLE" || _vm.newState === "PUBLISHED"
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass: "text-body-2",
                            attrs: {
                              outlined: "",
                              type:
                                _vm.newState === "PUBLISHED"
                                  ? "warning"
                                  : "error"
                            }
                          },
                          [
                            _vm._v(
                              " Course content will immediately become " +
                                _vm._s(
                                  _vm.newState === "PUBLISHED"
                                    ? "available"
                                    : "unavailable and cannot be reinstated"
                                ) +
                                " to all learners. "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey lighten-2" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: { color: "secondary", disabled: _vm.disableSave },
                      on: { click: _vm.saveVisibility }
                    },
                    [
                      _vm._v(
                        " Confirm" +
                          _vm._s(
                            _vm.disableSave ? ` (${_vm.countdown}s)` : ""
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }