var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c("h1", [_vm._v(_vm._s(_vm.courseGroup?.title))]),
                  _c("breadcrumbs", {
                    attrs: { items: ["Course Groups", _vm.courseGroup?.title] }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          { staticClass: "text--primary mb-3 font-weight-bold text-h6" },
          [_vm._v(" Course Grouping Configuration ")]
        ),
        _c(
          "div",
          { staticClass: "rounded-lg white px-5 py-1 text--primary" },
          [
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Course Grouping Title ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: { "current-value": _vm.courseGroup?.title },
                      on: { "update-value": _vm.updateTitle }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-2",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Course Grouping Description ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c("edit-field", {
                      attrs: { "current-value": _vm.courseGroup?.description },
                      on: { "update-value": _vm.updateDescription }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "info-row py-4",
                attrs: { "no-gutters": "", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "mr-auto title-column",
                    attrs: { cols: "auto" }
                  },
                  [_vm._v(" Last Updated At ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDate(_vm.courseGroup?.updatedAt)) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text--primary mb-3 font-weight-bold text-h6",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Courses Included ")]
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm.courseContent?.state !== "UNAVAILABLE" &&
                  _vm.courseContent?.state !== "PUBLISHED"
                    ? _c("create-course-modal", {
                        on: {
                          refetch: function($event) {
                            return _vm.$apollo.queries.coursesForCourseGroup.refetch()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("courses-table", {
            attrs: {
              courses: _vm.coursesForCourseGroup,
              loading: _vm.$apollo.queries.coursesForCourseGroup.loading,
              "course-group": true
            },
            on: { "click-delete": _vm.deleteCourseFromGroup }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }