<template>
  <div class="tabbed-page">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <h1>{{ courseContent?.title }}</h1>
          <breadcrumbs
            :items="['Publish Content', courseContent?.title]"
          />
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <change-visibility-modal
            v-if="
              courseContent?.state !== 'UNAVAILABLE' &&
                courseContent?.state !== 'PUBLISHED'
            "
            :current-state="courseContent?.state"
            @refetch="$apollo.queries.courseContent.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-tabs
      v-model="tab"
      active-class="active-tab"
      background-color="primary"
      dark
    >
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="rounded-b-lg white px-5 py-1 text--primary">
          <v-row no-gutters align="center" class="info-row py-2">
            <v-col cols="auto" class="mr-auto title-column">
              Update Name
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <edit-field
                :current-value="courseContent?.title"
                @update-value="updateTitle"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="info-row py-2">
            <v-col cols="auto" class="mr-auto title-column">
              Version Number
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <edit-field
                :current-value="courseContent?.version"
                @update-value="updateVersion"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="info-row py-4">
            <v-col cols="auto" class="mr-auto title-column">
              Show Release Notes to User
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <div>
                <v-switch
                  :key="showToUserKey"
                  :input-value="
                    courseContent?.releaseNotes?.showToUser ?? false
                  "
                  inset
                  hide-details
                  class="my-n2"
                  @change="updateShowReleaseNotesToUser"
                />
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="info-row py-2">
            <v-col cols="auto" class="mr-auto title-column">
              Release Notes Title (Visible To Learners)
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <edit-field
                :current-value="courseContent?.releaseNotes?.title"
                @update-value="updateReleaseNotesTitle"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="info-row py-2">
            <v-col cols="auto" class="mr-auto title-column">
              Release Notes (Visible To Learners)
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <edit-field
                :current-value="
                  courseContent?.releaseNotes?.releaseNotes
                "
                @update-value="updateReleaseNotes"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="info-row py-4">
            <v-col cols="auto" class="mr-auto title-column">
              Visibility
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <span
                class="text-capitalize"
                :style="
                  `color: ${getStateColour(courseContent?.state)}`
                "
              >
                {{ courseContent?.state.toLowerCase() }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="info-row py-2">
            <v-col cols="auto" class="mr-auto title-column">
              Update Summary
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <edit-field
                :current-value="courseContent?.summary"
                @update-value="updateSummary"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="info-row py-4">
            <v-col cols="auto" class="mr-auto title-column">
              Last Updated At
            </v-col>
            <v-col cols="auto" class="ml-auto">
              {{ formatDate(courseContent?.updatedAt) }}
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="pa-5">
          <div class="text--secondary text-body-2">
            The following information outlines how the Ask Annie app
            will behave when content changes occur:
          </div>
          <v-divider class="my-5" />
          <div class="primary--text">
            <h3>Added Questions to Lessons</h3>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              Users with 100%:
            </div>
            <div class="text-body-2 font-weight-light">
              The lesson will continue to be marked as 100%. Next time
              they attempt it, they will see the new question. This
              lesson still appears in the backend, PDF export, and CSV
              export
            </div>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              Users scored less than 100%:
            </div>
            <div class="text-body-2 font-weight-light">
              The lesson remain as incomplete. This lesson still
              appears in the backend, PDF export, and CSV export.
            </div>
          </div>
          <v-divider class="my-5" />
          <div class="primary--text">
            <h3>Archived Questions from Lessons</h3>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              Users with 100%:
            </div>
            <div class="text-body-2 font-weight-light">
              The lesson will continue to be marked as 100%. Next time
              they attempt it, they will see the new question. This
              lesson still appears in the backend, PDF export, and CSV
              export.
            </div>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              Users scored less than 100%:
            </div>
            <div class="text-body-2 font-weight-light">
              The lesson remain as incomplete. This lesson still
              appears in the backend, PDF export, and CSV export.
            </div>
          </div>
          <v-divider class="my-5" />
          <div class="primary--text">
            <h3>Archived Module</h3>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              User have attempted lessons in the module:
            </div>
            <div class="text-body-2 font-weight-light">
              The user will no longer see the module in the mobile
              app. However, any completed lessons will continue to be
              visible in the backend, PDF export, and CSV report. .
            </div>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              User has not attempted lessons in the module:
            </div>
            <div class="text-body-2 font-weight-light">
              All lessons remain as incomplete. This module will not
              appear in learner’s view on the Ask Annie website.
            </div>
          </div>
          <v-divider class="my-5" />
          <div class="primary--text">
            <h3>Archived Course</h3>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              User have completed any content in a course:
            </div>
            <div class="text-body-2 font-weight-light">
              The learner will no longer see the course or content
              associated with it. However, any completed lessons will
              continue to be visible in the backend, PDF export, and
              CSV report.
            </div>
            <div class="font-weight-bold text-body-2 mt-5 mb-2">
              User have not completed any content in the course:
            </div>
            <div class="text-body-2 font-weight-light">
              The user will no longer see the course.
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CoursesTable from '@/components/Course/CoursesTable';
import ChangeVisibilityModal from '@/components/Course/ChangeVisibilityModal';
import CreateCourseModal from '@/components/Course/CreateCourseModal';
import { Courses } from '@/graphql/Course.gql';
import {
  CourseContent,
  UpdateCourseContent
} from '@/graphql/CourseContent.gql';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import { formatDate } from '@/utils/date';
import { getStateColour } from '@/utils/courseContent';
import EditField from '@/components/Common/EditField';

export default {
  name: 'PublishOverview',
  components: {
    CoursesTable,
    CreateCourseModal,
    Breadcrumbs,
    ChangeVisibilityModal,
    EditField
  },
  data: () => ({
    tab: 0,
    items: ['Overview', 'How does Ask Annie handle changes?'],
    showToUserKey: 0
  }),
  computed: {
    courseContentId() {
      return this.$route.params.courseContentId;
    },
    defaultCourseContent() {
      const releaseNotes = this.courseContent?.releaseNotes;
      if (releaseNotes && releaseNotes.__typename) {
        delete releaseNotes.__typename;
      }
      return {
        id: this.courseContent?.id,
        title: this.courseContent?.title,
        summary: this.courseContent?.summary,
        version: this.courseContent?.version,
        state: this.courseContent?.state,
        releaseNotes,
        createdBy: this.courseContent?.createdBy.id
      };
    }
  },
  apollo: {
    courses: {
      query: Courses
    },
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    getStateColour(state) {
      return getStateColour(state);
    },
    async updateCourseContent(courseContent) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateCourseContent,
          variables: {
            courseContent: courseContent
          }
        });

        await this.$apollo.queries.courseContent.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
        this.showToUserKey++;
      }
    },
    async updateTitle(title) {
      await this.updateCourseContent({
        ...this.defaultCourseContent,
        title: title
      });
    },
    async updateVersion(version) {
      await this.updateCourseContent({
        ...this.defaultCourseContent,
        version: version
      });
    },
    async updateSummary(summary) {
      await this.updateCourseContent({
        ...this.defaultCourseContent,
        summary: summary
      });
    },
    async updateReleaseNotesTitle(title) {
      await this.updateCourseContent({
        ...this.defaultCourseContent,
        releaseNotes: {
          title,
          releaseNotes:
            this.defaultCourseContent.releaseNotes?.releaseNotes ??
            '',
          showToUser:
            this.defaultCourseContent.releaseNotes?.showToUser ??
            false
        }
      });
    },
    async updateReleaseNotes(releaseNotes) {
      await this.updateCourseContent({
        ...this.defaultCourseContent,
        releaseNotes: {
          releaseNotes,
          title: this.defaultCourseContent.releaseNotes?.title ?? '',
          showToUser:
            this.defaultCourseContent.releaseNotes?.showToUser ??
            false
        }
      });
    },
    async updateShowReleaseNotesToUser(showToUser) {
      if (showToUser) {
        if (
          !this.defaultCourseContent?.releaseNotes?.title?.trim() ||
          !this.defaultCourseContent?.releaseNotes?.releaseNotes?.trim()
        ) {
          this.$toast.error(
            'Please fill in the Release Notes Title & Release Notes fields first.'
          );
          this.showToUserKey++;
          return;
        }
      }
      await this.updateCourseContent({
        ...this.defaultCourseContent,
        releaseNotes: {
          title: this.defaultCourseContent.releaseNotes?.title ?? '',
          releaseNotes:
            this.defaultCourseContent.releaseNotes?.releaseNotes ??
            '',
          showToUser: showToUser ?? false
        }
      });
    }
  }
};
</script>
