import Vue from 'vue';
import VueRouter from 'vue-router';
import LearnerListPage from '@/pages/LearnerListPage';
import Login from '@/pages/Login';
import ResetPassword from '@/pages/ResetPassword';
import ApplicationLayout from '@/layouts/ApplicationLayout';
import { auth } from '@/main';
import OrganisationList from '@/pages/Organisations/OrganisationList';
import OrganisationLayout from '@/layouts/OrganisationLayout';
import OrganisationSites from '@/pages/Organisations/OrganisationSites';
import OrganisationBundles from '@/pages/Organisations/OrganisationBundles';
import OrganisationLearners from '@/pages/Organisations/OrganisationLearners';
import OrganisationStaff from '@/pages/Organisations/OrganisationStaff';
import OrganisationPendingRequests from '@/pages/Organisations/OrganisationPendingRequests';
import UserDetails from '@/pages/UserDetails';
import OrganisationSiteDetails from '@/pages/Organisations/OrganisationSiteDetails';
import StaffListPage from '@/pages/StaffListPage';
import AaaConfig from '@/pages/Aaa/AaaConfig';
import OrganisationArchivedLearners from '@/pages/Organisations/OrganisationArchivedLearners';
import PublishPage from '@/pages/PublishPage';
import CourseContentCoursesPage from '@/pages/CourseContent/CourseContentCoursesPage';
import CourseContentLayout from '@/layouts/CourseContentLayout';
import CourseGroupPage from '@/pages/CourseContent/CourseGroupPage';
import CoursePage from '@/pages/CourseContent/CoursePage';
import CourseGroupsPage from '@/pages/CourseContent/CourseGroupsPage';
import PublishOverview from '@/pages/CourseContent/PublishOverview';
import CoursesPage from '@/pages/CoursesPage';
import QuestionPage from '@/pages/QuestionPage';
import LessonsPage from '@/pages/LessonsPage';
import LessonPage from '@/pages/LessonPage';
import ModulesPage from '@/pages/ModulesPage';
import ModulePage from '@/pages/ModulePage';
import CourseContentModulesPage from '@/pages/CourseContent/CourseContentModulesPage';
import CourseContentLessonsPage from '@/pages/CourseContent/CourseContentLessonsPage';
import OrganisationBundleDetails from '@/pages/Organisations/OrganisationBundleDetails';
import store from '@/store';
import SystemRoles from '@/enums/SystemRoles';
import OrganisationOverview from '@/pages/Organisations/OrganisationOverview';
import BundlesPage from '../pages/BundlesPage.vue';
import BundleDetailsPage from '../pages/BundleDetailsPage.vue';
import DementiaFactsPage from '@/pages/DementiaFactsPage';
import AppInstructionsPage from '@/pages/AppInstructions/AppInstructionsPage.vue';
import UserCompletionData from '@/pages/UserCompletionData.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/password-reset',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '',
    component: ApplicationLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/learners',
        name: 'LearnerListPage',
        component: LearnerListPage,
        meta: {
          adminOnly: true
        }
      },
      {
        path: '/publish',
        name: 'PublishPage',
        component: PublishPage
      },
      {
        path: '/bundles',
        name: 'BundlesPage',
        component: BundlesPage
      },
      {
        path: '/bundles/:bundleId',
        name: 'BundleDetailsPage',
        component: BundleDetailsPage
      },
      {
        path: '/courses',
        name: 'CoursesPage',
        component: CoursesPage
      },
      {
        path: '/courses/:courseId',
        name: 'CoursePage',
        component: CoursePage
      },
      {
        path: '/dementia-facts',
        name: 'DementiaFactsPage',
        component: DementiaFactsPage
      },
      {
        path: '/modules',
        name: 'ModulesPage',
        component: ModulesPage
      },
      {
        path: '/modules/:moduleId',
        name: 'ModulePage',
        component: ModulePage
      },
      {
        path: '/lessons',
        name: 'LessonsPage',
        component: LessonsPage
      },
      {
        path: '/lessons/:lessonId',
        name: 'LessonPage',
        component: LessonPage
      },
      {
        path: '/lessons/:lessonId/question/:questionId',
        name: 'QuestionPage',
        component: QuestionPage
      },
      {
        path: '/learners/:userAccountId',
        name: 'UserDetails',
        component: UserDetails
      },
      {
        path: '/learners/:userAccountId/completion-data',
        name: 'UserCompletionData',
        component: UserCompletionData
      },
      {
        path: '/staff',
        name: 'StaffListPage',
        component: StaffListPage,
        meta: {
          adminOnly: true
        }
      },
      {
        path: '/staff/:userAccountId',
        name: 'StaffDetails',
        component: UserDetails
      },
      {
        path: '/organisations',
        name: 'Organisations',
        component: OrganisationList,
        meta: {
          adminOnly: true
        }
      },
      {
        path: '/aaa-config',
        name: 'Ask Annie Anything Configuration',
        component: AaaConfig
      },
      {
        path: '/app-instructions',
        name: 'AppInstructions',
        component: AppInstructionsPage,
        meta: {
          requiresAuth: true,
          roles: [SystemRoles.CMS_ADMIN]
        }
      }
    ]
  },
  {
    path: '',
    component: CourseContentLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/course-content/:courseContentId/courses',
        name: 'CourseContentCoursesPage',
        component: CourseContentCoursesPage
      },
      {
        path: '/course-content/:courseContentId/courses/:courseId',
        name: 'CoursePage',
        component: CoursePage
      },
      {
        path: '/course-content/:courseContentId/modules',
        name: 'CourseContentModulesPage',
        component: CourseContentModulesPage
      },
      {
        path: '/course-content/:courseContentId/modules/:moduleId',
        name: 'ModulePage',
        component: ModulePage
      },
      {
        path: '/course-content/:courseContentId/lessons',
        name: 'CourseContentLessonsPage',
        component: CourseContentLessonsPage
      },
      {
        path: '/course-content/:courseContentId/lessons/:lessonId',
        name: 'LessonPage',
        component: LessonPage
      },
      {
        path:
          '/course-content/:courseContentId/lessons/:lessonId/question/:questionId',
        name: 'QuestionPage',
        component: QuestionPage
      },
      {
        path:
          '/course-content/:courseContentId/lessons/:lessonId/question/:questionId',
        name: 'QuestionPage',
        component: QuestionPage
      },
      {
        path: '/course-content/:courseContentId/overview',
        name: 'PublishOverview',
        component: PublishOverview
      },
      {
        path: '/course-content/:courseContentId/courses',
        name: 'CoursesPage',
        component: CourseContentCoursesPage
      },
      {
        path: '/course-content/:courseContentId/courses/:courseId',
        name: 'CoursePage',
        component: CoursePage
      },
      {
        path: '/course-content/:courseContentId/course-groups',
        name: 'CourseGroupPage',
        component: CourseGroupsPage
      },
      {
        path:
          '/course-content/:courseContentId/course-groups/:courseGroupId',
        name: 'CourseGroupPage',
        component: CourseGroupPage
      }
    ]
  },
  {
    path: '',
    component: OrganisationLayout,
    meta: {
      requiresAuth: true,
      adminOnly: true
    },
    children: [
      {
        path: '/organisation/:organisationId/overview',
        name: 'OrganisationOverview',
        component: OrganisationOverview
      },
      {
        path: '/organisation/:organisationId/sites',
        name: 'OrganisationSites',
        component: OrganisationSites
      },
      {
        path: '/organisation/:organisationId/sites/:siteId',
        name: 'OrganisationSiteDetails',
        component: OrganisationSiteDetails
      },
      {
        path:
          '/organisation/:organisationId/sites/:siteId/staff/:userAccountId',
        name: 'OrganisationStaffDetails',
        component: UserDetails
      },
      {
        path: '/organisation/:organisationId/bundles',
        name: 'OrganisationBundles',
        component: OrganisationBundles
      },
      {
        path: '/organisation/:organisationId/bundles/:bundleId',
        name: 'OrganisationBundleDetails',
        component: OrganisationBundleDetails
      },
      {
        path: '/organisation/:organisationId/learners',
        name: 'OrganisationLearners',
        component: OrganisationLearners
      },
      {
        path: '/organisation/:organisationId/archived-learners',
        name: 'OrganisationArchivedLearners',
        component: OrganisationArchivedLearners
      },
      {
        path: '/organisation/:organisationId/learners/:userAccountId',
        name: 'OrganisationUserDetails',
        component: UserDetails
      },
      {
        path:
          '/organisation/:organisationId/learners/:userAccountId/completion-data',
        name: 'OrganisationUserCompletionData',
        component: UserCompletionData
      },
      {
        path: '/organisation/:organisationId/staff/:userAccountId',
        name: 'OrganisationStaffDetails',
        component: UserDetails
      },
      {
        path: '/organisation/:organisationId/staff',
        name: 'OrganisationStaff',
        component: OrganisationStaff
      },
      {
        path: '/organisation/:organisationId/pending-requests',
        name: 'OrganisationPendingRequests',
        component: OrganisationPendingRequests
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  // Check if the route requires authentication
  const requiresAuth = to.matched.some(
    record => record.meta.requiresAuth
  );
  // Check if the route is for admin only
  const adminOnly = to.matched.some(record => record.meta.adminOnly);

  // Redirect to login if authentication is required but user is not logged in
  if (requiresAuth && !currentUser) next('/login');
  // Redirect to organisations page if user is logged in but accessing a non-auth route
  else if (!requiresAuth && currentUser) next('/organisations');

  // Get the current user's account from the store
  const currentUserAccount = store?.state?.user?.currentUserAccount;

  // Function to check if the user has an active staff membership
  const isStaffAccount = () => {
    if (currentUserAccount?.systemRole === 'USER') {
      // Filter active staff memberships (non-learner roles)
      const activeStaffMemberships = currentUserAccount?.memberships?.filter(
        membership =>
          !membership.archivedAt &&
          membership.membershipState === 'ACTIVE' &&
          membership.memberRole !== 'LEARNER'
      );

      // Return true if there are any active staff memberships
      return activeStaffMemberships.length > 0;
    }
  };

  // Check access for admin-only routes
  if (
    adminOnly &&
    store?.state?.user?.currentUserAccount?.systemRole !==
      SystemRoles.DEMENTIA_ADMIN &&
    !isStaffAccount()
  ) {
    // Redirect to publish page if user is not an admin or staff member
    next('/publish');
  } else {
    // Allow access to the requested route
    next();
  }
});

export default router;
