import enumToDisplay from '@/utils/enumToDisplay';

const ContentType = Object.freeze({
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  PDF: 'PDF'
});

export const contentTypeItems = enumToDisplay(ContentType);

export default ContentType;
