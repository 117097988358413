<template>
  <div>
    <v-dialog v-model="open" width="500">
      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          {{ existingDementiaFact ? 'Update' : 'New' }} Fact
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="createDementiaFactForm">
            <v-label>Title</v-label>
            <v-text-field
              v-model="title"
              :rules="rules.requiredField"
              label="Title"
              single-line
              outlined
              dense
              required
            />

            <v-label>Content</v-label>
            <v-textarea
              v-model="content"
              :rules="rules.requiredField"
              rows="3"
              label="Content"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="close">
            Cancel
          </v-btn>
          <v-btn color="secondary" class="primary--text" @click="createDementiaFact">
            {{ existingDementiaFact ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  CreateDementiaFact,
  UpdateDementiaFact
} from '@/graphql/DementiaFacts.gql';

export default {
  name: 'CreateDementiaFactModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    existingDementiaFact: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    id: '',
    title: '',
    content: ''
  }),
  watch: {
    open(open) {
      this.$refs.createDementiaFactForm?.reset();

      if (open) {
        this.resetForm();

        if (this.existingDementiaFact) {
          this.id = this.existingDementiaFact.id;
          this.title = this.existingDementiaFact.title;
          this.content = this.existingDementiaFact.content;
        }
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUserAccount'])
  },
  methods: {
    resetForm() {
      this.id = '';
      this.title = '';
      this.content = '';
    },
    close() {
      this.$emit('close');
    },
    async createDementiaFact() {
      if (this.$refs.createDementiaFactForm.validate()) {
        try {
          await this.$apollo.mutate({
            mutation: this.existingDementiaFact
              ? UpdateDementiaFact
              : CreateDementiaFact,
            variables: {
              dementiaFact: {
                id: this.id,
                title: this.title,
                content: this.content
              }
            }
          });

          this.$emit('refetch');
          this.close();
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        }
      }
    }
  }
};
</script>
