<template>
  <div v-if="!loadingUserAccount">
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col class="ml-n3 mr-1" cols="auto">
              <v-btn color="#00000060" icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <h1>Overview of {{ site.name }}</h1>
            </v-col>
          </v-row>

          <breadcrumbs :items="['Site', site.name]" />
        </v-col>
      </v-row>
    </v-container>

    <div>
      <div class="text--primary mb-3 font-weight-bold text-h6">
        Site Overview
      </div>
      <div class="rounded-lg white px-5 py-1 text--primary">
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Site Name
          </v-col>
          <v-col cols="auto" class="ml-auto value-column">
            <edit-field
              :current-value="site?.name"
              @update-value="updateSiteName"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-2">
          <v-col cols="auto" class="mr-auto title-column">
            Data Sharing Code
          </v-col>
          <v-col cols="auto" class="ml-auto value-column">
            <edit-field
              :current-value="site?.siteCode"
              @update-value="updateSiteCode"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="info-row py-4">
          <v-col cols="auto" class="mr-auto title-column">
            Automatically Approve New Data Sharing Requests
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon size="20" class="ml-2"
                    >mdi-help-circle-outline</v-icon
                  >
                </span>
              </template>
              <span
                >If toggled on, your organisation automatically
                approves all learner data share requests. If toggled
                off, your organisation must manually review and
                approve each learner data share request.</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <v-switch
              :input-value="site?.publicSite"
              @change="updatePublicSite"
              hide-details
              class="mt-0"
              inset
            ></v-switch>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Site Management
        </v-col>

        <v-col cols="auto">
          <invite-user-account-modal
            dense
            staff
            :organisation-id="organisationId"
            :site-id="siteId"
            @refetch="$apollo.queries.siteStaff.refetch()"
          />
        </v-col>
      </v-row>

      <organisation-staff-table
        :staff="siteStaff"
        :loading="$apollo.queries.siteStaff.loading"
      />
    </div>

    <div class="mt-8">
      <v-row justify="space-between" align="center">
        <v-col
          cols="auto"
          class="text--primary mb-3 font-weight-bold text-h6"
        >
          Site Learners
        </v-col>
      </v-row>

      <learners
        :learners="siteLearners"
        :loading="$apollo.queries.siteLearners.loading"
      />
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Site,
  SiteStaff,
  UpdateSite,
  SiteLearners
} from '@/graphql/Site.gql';
import Breadcrumbs from '@/components/Common/Breadcrumbs';
import { formatDate } from '@/utils/date';
import OrganisationStaffTable from '@/components/Organisation/Staff/OrganisationStaffTable';
import InviteUserAccountModal from '@/components/Common/InviteUserAccountModal';
import EditField from '@/components/Common/EditField';
import Learners from '@/components/Common/Learners';

export default {
  name: 'OrganisationSiteDetails',
  components: {
    Breadcrumbs,
    OrganisationStaffTable,
    InviteUserAccountModal,
    EditField,
    Learners
  },
  computed: {
    ...mapState('user', ['currentUserAccount', 'loadingUserAccount']),
    organisationId() {
      return this.$route.params.organisationId;
    },
    siteId() {
      return this.$route.params.siteId;
    },
    defaultSite() {
      return {
        id: this.site?.id,
        name: this.site?.name,
        address: this.site?.address,
        country: this.site?.country,
        website: this.site?.website,
        siteCode: this.site?.siteCode,
        publicSite: this.site?.publicSite,
        organisation: this.site?.organisation.id
      };
    }
  },
  apollo: {
    site: {
      query: Site,
      variables() {
        return {
          id: this.siteId
        };
      }
    },
    siteStaff: {
      query: SiteStaff,
      variables() {
        return {
          siteId: this.siteId
        };
      }
    },
    siteLearners: {
      query: SiteLearners,
      variables() {
        return {
          organisationId: this.organisationId,
          siteId: this.siteId
        };
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    async updateSite(site) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateSite,
          variables: {
            site: site
          }
        });

        await this.$apollo.queries.site.refetch();
      } catch (e) {
        e.graphQLErrors.map(x => this.$toast.error(x.message));
      }
    },
    async updateSiteName(name) {
      await this.updateSite({
        ...this.defaultSite,
        name: name
      });
    },
    async updateSiteCode(code) {
      await this.updateSite({
        ...this.defaultSite,
        siteCode: code
      });
    },
    async updatePublicSite(visible) {
      await this.updateSite({
        ...this.defaultSite,
        publicSite: visible
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-row {
  font-size: 14px;

  .title-column {
    font-weight: 500;
  }

  .value-column {
    font-weight: 300;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
