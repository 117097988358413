<template>
  <v-dialog :value="open" width="500" @click:outside="$emit('close')">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        New Bundle
      </v-card-title>

      <v-card-text class="py-2">
        <v-form v-model="valid" ref="createBundleForm">
          <v-label>Name</v-label>
          <v-text-field
            v-model="name"
            :rules="rules.requiredField"
            label="Name"
            single-line
            outlined
            dense
            required
          />

          <v-label>Bundle Code</v-label>
          <v-text-field
            v-model="bundleCode"
            :rules="rules.bundleCode"
            class="bundle-code-text-input"
            label="Bundle Code"
            single-line
            outlined
            dense
            required
          />

          <v-switch
            v-model="active"
            class="mt-0"
            label="Mark the bundle as active"
            inset
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="primary--text" @click="createBundle">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EmployeeIdentifier from '@/enums/EmployeeIdentifier';
import { CreateBundle } from '@/graphql/Bundle.gql';

export default {
  name: 'CreateBundleModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    organisationId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    valid: false,
    name: '',
    bundleCode: '',
    active: true,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ],
      bundleCode: [
        val =>
          (val || '').length === 3 ||
          'Bundle code must be 3 characters'
      ]
    }
  }),
  watch: {
    open() {
      this.$refs.createOrganisationForm.reset();
    }
  },
  computed: {
    employeeIdentifierOptions() {
      return Object.entries(EmployeeIdentifier).map(entry => ({
        text: entry[1],
        value: entry[0]
      }));
    }
  },
  methods: {
    createBundle() {
      if (this.$refs.createBundleForm.validate()) {
        this.$apollo
          .mutate({
            mutation: CreateBundle,
            variables: {
              bundle: {
                name: this.name,
                bundleCode: this.bundleCode,
                active: this.active,
                organisation: this.organisationId
              }
            }
          })
          .then(() => this.$emit('created'))
          .catch(e =>
            e.graphQLErrors.map(x => this.$toast.error(x.message))
          );
      }
    }
  }
};
</script>

<style lang="scss">
.bundle-code-text-input
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input {
  text-transform: uppercase;
}
</style>
