var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ml-n3 mr-1", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "#00000060", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.go(-1)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("h1", [
                          _vm._v(
                            " Learner Data: " +
                              _vm._s(
                                [
                                  _vm.userAccount?.firstName,
                                  _vm.userAccount?.lastName
                                ].join(" ")
                              ) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("breadcrumbs", { attrs: { items: _vm.breadcrumbItems } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "mt-8" }, [
        _c(
          "div",
          { staticClass: "rounded-lg white px-5 text--primary" },
          [
            _c("v-data-table", {
              staticClass: "dark--text rounded-b-lg rounded-t-0",
              attrs: {
                items: _vm.formattedLessonCompletionsForUser,
                headers: _vm.summaryOfCourseCompletionHeaders,
                loading: _vm.$apollo.queries.lessonCompletionsForUser.loading,
                "no-data-text": "No completions recorded for this user",
                "loading-text": "Loading – this can take up to 60 seconds...",
                "item-key": "id"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.courseAvailability`,
                    fn: function({ item }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "text-capitalize",
                            style: `color: ${_vm.getStateColour(
                              item.courseAvailability
                            )}`
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.courseAvailability
                                    .replaceAll("_", " ")
                                    .toLowerCase()
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }