<template>
  <v-dialog :value="open" width="500" @click:outside="$emit('close')">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        New Site
      </v-card-title>

      <v-card-text class="py-2">
        <v-form v-model="valid" ref="createSiteForm">
          <v-label>Name</v-label>
          <v-text-field
            v-model="name"
            :rules="rules.requiredField"
            label="Name"
            single-line
            outlined
            dense
            required
          />

          <v-label>Address</v-label>
          <v-text-field
            v-model="address"
            :rules="rules.requiredField"
            label="Address"
            single-line
            outlined
            dense
            required
          />

          <v-label>Country</v-label>
          <v-select
            v-model="country"
            :rules="rules.requiredField"
            :items="countryOptions"
            label="Country"
            single-line
            outlined
            dense
            required
          />

          <v-label>Website</v-label>
          <v-text-field
            v-model="website"
            label="Website"
            single-line
            outlined
            dense
          />

          <v-label>Site Code</v-label>
          <v-text-field
            v-model="siteCode"
            :rules="rules.siteCode"
            class="site-code-text-input"
            label="Site Code"
            single-line
            outlined
            dense
            required
          />

          <v-switch
            v-model="privateSite"
            class="mt-0"
            label="New data share requests require approval"
            inset
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="primary--text" @click="createSite">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EmployeeIdentifier from '@/enums/EmployeeIdentifier';
import { CreateSite } from '@/graphql/Site.gql';
import { countryData } from '@/data/countries';

export default {
  name: 'CreateSiteModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    organisationId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    valid: false,
    name: '',
    address: '',
    country: 'Australia',
    website: '',
    siteCode: '',
    privateSite: true,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ],
      siteCode: [
        val =>
          (val || '').length === 3 || 'Site code must be 3 characters'
      ]
    }
  }),
  watch: {
    open() {
      this.$refs.createSiteForm.reset();

      if (this.open) {
        this.country = 'Australia';
      }
    }
  },
  computed: {
    employeeIdentifierOptions() {
      return Object.entries(EmployeeIdentifier).map(entry => ({
        text: entry[1],
        value: entry[0]
      }));
    },
    countryOptions() {
      return countryData.map(country => ({
        value: country.country,
        text: country.country
      }));
    }
  },
  methods: {
    createSite() {
      if (this.$refs.createSiteForm.validate()) {
        this.$apollo
          .mutate({
            mutation: CreateSite,
            variables: {
              site: {
                name: this.name,
                address: this.address,
                country: this.country,
                website: this.website,
                siteCode: this.siteCode,
                publicSite: !this.privateSite,
                organisation: this.organisationId
              }
            }
          })
          .then(() => this.$emit('created'))
          .catch(e =>
            e.graphQLErrors.map(x => this.$toast.error(x.message))
          );
      }
    }
  }
};
</script>

<style lang="scss">
.site-code-text-input
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input {
  text-transform: uppercase;
}
</style>
