var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          attrs: {
            icon: _vm.overrideText ? false : true,
            color: "error",
            disabled:
              _vm.disabled ||
              _vm.courseContent?.state === "PUBLISHED" ||
              _vm.courseContent?.state === "UNAVAILABLE",
            small: _vm.small
          },
          on: { click: _vm.confirmDelete }
        },
        [
          _c(
            "v-icon",
            {
              class: _vm.overrideText ? "mr-1" : "",
              attrs: { size: _vm.overrideIconSize ?? "20" }
            },
            [_vm._v(_vm._s(_vm.overrideIcon ?? "mdi-delete-outline"))]
          ),
          _vm._v(" " + _vm._s(_vm.overrideText) + " ")
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg pa-2" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" Are you sure? ")]
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1 white--text" },
                      on: { click: _vm.deleteItem }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v(
                          _vm._s(_vm.overrideIcon ?? "mdi-trash-can-outline")
                        )
                      ]),
                      _vm._v(_vm._s(_vm.overrideText ?? "Delete") + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }