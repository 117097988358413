var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1000" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.disabled,
                            small: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v("Module")],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [
                  _vm._v(
                    " Select Module " +
                      _vm._s(_vm.courseTitle ? `for ${_vm.courseTitle}` : "") +
                      " "
                  )
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c("modules-table", {
                    ref: "modulesTable",
                    attrs: {
                      modules: _vm.filteredModules,
                      loading: _vm.$apollo.queries.modules.loading,
                      "allow-selects": true
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: { color: "secondary" },
                      on: { click: _vm.addSelectedModules }
                    },
                    [_vm._v(" Add Modules ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }