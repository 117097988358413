<template>
  <v-dialog :value="open" width="500" @click:outside="$emit('close')">
    <v-card class="rounded-lg">
      <v-card-title class="h6 font-weight-bold primary--text">
        New Organisation
      </v-card-title>

      <v-card-text class="py-2">
        <v-form v-model="valid" ref="createOrganisationForm">
          <v-label>Name</v-label>
          <v-text-field
            v-model="companyName"
            :rules="rules.requiredField"
            label="Name"
            single-line
            outlined
            dense
            required
          />

          <v-label>Logo</v-label>
          <v-file-input
            ref="fileInput"
            v-model="input.file"
            label="Logo"
            @change="validateFile"
            :rules="rules.logo"
            single-line
            outlined
            dense
            required
          />

          <v-label>Address</v-label>
          <v-text-field
            v-model="address"
            :rules="rules.requiredField"
            label="Address"
            single-line
            outlined
            dense
            required
          />

          <v-label>Country</v-label>
          <v-select
            v-model="country"
            :rules="rules.requiredField"
            :items="countryOptions"
            label="Country"
            single-line
            outlined
            dense
            required
          />

          <v-label>Website</v-label>
          <v-text-field
            v-model="website"
            label="Website"
            single-line
            outlined
            dense
          />

          <v-label>Organisation Code</v-label>
          <v-text-field
            v-model="organisationCode"
            :rules="rules.organisationCode"
            class="organisation-code-text-input"
            label="Organisation Code"
            single-line
            outlined
            dense
            required
          />

          <v-label>Employee Identifier</v-label>
          <v-select
            v-model="employeeIdentifier"
            :rules="rules.requiredField"
            :items="employeeIdentifierOptions"
            label="Employee Identifier"
            single-line
            outlined
            dense
            required
          />
        </v-form>
      </v-card-text>

      <v-progress-linear indeterminate v-if="isLoading"></v-progress-linear>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="primary--text" @click="createOrganisation">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EmployeeIdentifier from '@/enums/EmployeeIdentifier';
import { CreateOrganisation } from '@/graphql/Organisation.gql';
import { countryData } from '@/data/countries';
import axios from 'axios';
import moment from 'moment';
import {
  CreateBucketObjectWithName,
  RequestUploadUrl
} from '@/graphql/BucketObject.gql';

export default {
  name: 'CreateOrganisationModal',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false,
    companyName: '',
    address: '',
    country: 'Australia',
    website: '',
    organisationCode: '',
    employeeIdentifier: '',
    input: {},
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ],
      organisationCode: [
        val =>
          (val || '').length === 3 ||
          'Organisation code must be 3 characters'
      ],
      logo: [val => !!val || 'This field is required']
    },
    isLoading: false
  }),
  watch: {
    open() {
      this.$refs.createOrganisationForm.reset();

      if (this.open) {
        this.country = 'Australia';
        this.input = {};
      }
    }
  },
  computed: {
    employeeIdentifierOptions() {
      return Object.entries(EmployeeIdentifier).map(entry => ({
        text: entry[1],
        value: entry[0]
      }));
    },
    countryOptions() {
      return countryData.map(country => ({
        value: country.country,
        text: country.country
      }));
    }
  },
  methods: {
    validateFile() {
      this.input.name = this.input.file.name;
    },
    async createOrganisation() {
      if (this.$refs.createOrganisationForm.validate()) {
        this.isLoading = true;
        try {
          const response = await this.$apollo.mutate({
            mutation: CreateBucketObjectWithName,
            variables: {
              bucketObject: {
                objectId: `/organisation/${moment().unix()}-${this.input.name.replaceAll(
                  '/',
                  '-'
                )}`,
                fileType: this.input.file.type
              }
            }
          });
          const media = response.data.createBucketObjectWithName;
          const uploadUrlResponse = await this.$apollo.mutate({
            mutation: RequestUploadUrl,
            variables: {
              bucketObjectId: media.id
            }
          });
          const uploadUrl = uploadUrlResponse.data.requestUploadUrl;

          try {
            await axios.put(uploadUrl, this.input.file, {
              headers: { 'Content-Type': this.input.file.type }
            });

            await this.$apollo.mutate({
              mutation: CreateOrganisation,
              variables: {
                organisation: {
                  companyName: this.companyName,
                  logoUrl: media.id,
                  address: this.address,
                  country: this.country,
                  website: this.website,
                  organisationCode: this.organisationCode,
                  employeeIdentifier: this.employeeIdentifier
                }
              }
            });

            this.$emit('created');
          } catch (e) {
            e.graphQLErrors.map(x => this.$toast.error(x.message));
          }
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.organisation-code-text-input
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input {
  text-transform: uppercase;
}
</style>
