<template>
  <div>
    <v-container fluid class="primary--text mb-5">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-auto">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <h1>Learners</h1>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="auto" class="mr-auto">
              <div class="page-subtitle">
                A list of learners that currently share their data.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="ml-auto d-flex">
          <invite-user-account-modal
            @refetch="$apollo.queries.allLearners.refetch()"
          />
        </v-col>
      </v-row>
    </v-container>

    <paged-learners
      :learners="allLearners?.content"
      :loading="$apollo.queries.allLearners.loading"
      :total-pages="allLearners?.pageInfo.totalPages"
      :total-elements="allLearners?.pageInfo.totalElements"
      :current-page="currentPage"
      @page-changed="handlePageChange"
      @search-changed="handleSearchChange"
    />
  </div>
</template>

<script>
import { AllLearners } from '@/graphql/UserAccount.gql';
import PagedLearners from '@/components/Common/PagedLearners';
import InviteUserAccountModal from '@/components/Common/InviteUserAccountModal';

export default {
  name: 'UserList',
  components: {
    PagedLearners,
    InviteUserAccountModal
  },
  mounted() {
    this.$apollo.queries.allLearners.refetch();
  },
  data: () => ({
    currentPage: 0,
    pageSize: 10,
    currentSearch: ''
  }),
  apollo: {
    allLearners: {
      query: AllLearners,
      variables() {
        return {
          page: this.currentPage,
          size: this.pageSize,
          search: this.currentSearch
        };
      }
    }
  },
  computed: {
    totalElements() {
      return this.allLearners?.pageInfo?.totalElements ?? 0;
    },
    page() {
      return this.currentPage + 1;
    },
    totalPages() {
      return this.allLearners?.pageInfo?.totalPages ?? 0;
    }
  },
  methods: {
    handlePageChange(newPage) {
      this.currentPage = newPage - 1;
    },
    handleSearchChange(newSearch) {
      this.currentSearch = newSearch;
      this.currentPage = 0;
      this.$apollo.queries.allLearners.refetch();
    }
  }
};
</script>
