<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" small>
          <v-icon>mdi-plus</v-icon>Threshold</v-btn
        >
      </template>

      <v-card class="rounded-lg">
        <v-card-title class="h6 font-weight-bold primary--text">
          New Threshold
        </v-card-title>

        <v-card-text class="py-2">
          <v-form ref="crudThresholdForm">
            <v-label>Threshold</v-label>
            <v-text-field
              v-model="threshold"
              :rules="rules.requiredField"
              label="Threshold"
              single-line
              outlined
              dense
              required
            />

            <v-label>Header</v-label>
            <v-text-field
              v-model="header"
              :rules="rules.requiredField"
              label="Header"
              single-line
              outlined
              dense
              required
            />

            <v-label>Feedback</v-label>
            <v-text-field
              v-model="feedback"
              :rules="rules.requiredField"
              label="Feedback"
              single-line
              outlined
              dense
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" class="primary--text" @click="createCourse">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { SaveLessonThresholds } from '@/graphql/Lesson.gql';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CreateLessonFeedbackModal',
  data: () => ({
    dialog: false,
    rules: {
      requiredField: [
        val => (val || '').length > 0 || 'This field is required'
      ]
    },
    threshold: '',
    header: '',
    feedback: ''
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$refs.crudThresholdForm.reset();
      }

      this.resetForm();
    }
  },
  computed: {
    ...mapState('user', ['currentUserAccount'])
  },
  methods: {
    resetForm() {
      this.threshold = '';
      this.header = '';
      this.feedback = '';
    },
    async createCourse() {
      if (this.$refs.crudThresholdForm.validate()) {
        try {
          this.$emit('add-threshold', {
            id: uuidv4(),
            threshold: this.threshold,
            header: this.header,
            feedback: this.feedback
          });
          this.dialog = false;
        } catch (e) {
          e.graphQLErrors.map(x => this.$toast.error(x.message));
        }
      }
    }
  }
};
</script>
