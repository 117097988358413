<template>
  <div>
    <div class="page-sub-sec">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        hide-details
        class="shrink"
      />
    </div>
    <v-data-table
      disable-sort
      :items="learners"
      :headers="headers"
      :options.sync="options"
      :server-items-length="totalElements"
      :loading="loading"
      @update:options="$emit('page-changed', options.page)"
      class="dark--text rounded-b-lg rounded-t-0"
      :no-data-text="
        `There are no learners${
          search ? ' that match your search' : ''
        }`
      "
      item-key="id"
      @click:row="
        item =>
          $router.push(
            organisationId
              ? `/organisation/${organisationId}/learners/${item.id}`
              : `/learners/${item.id}`
          )
      "
    >
      <template v-slot:[`item.firstName`]="{ item }">
        {{ [item.firstName, item.lastName].join(' ') }}
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>

      <template v-slot:[`item.courseCompletion`]="{ item }">
        <span
          >{{ item.completedCourses }}/{{ item.totalCourses }}</span
        >
      </template>

      <template v-slot:[`item.moduleCompletion`]="{ item }">
        <span
          >{{ item.completedModules }}/{{ item.totalModules }}</span
        >
      </template>

      <template v-slot:[`item.lessonCompletion`]="{ item }">
        <span
          >{{ item.completedLessons }}/{{ item.totalLessons }}</span
        >
      </template>

      <template v-slot:[`item.editColumn`]="{ item }">
        <v-btn v-if="archived" icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="
            $router.push(
              organisationId
                ? `/organisation/${organisationId}/learners/${item.id}`
                : `/learners/${item.id}`
            )
          "
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-tooltip
          bottom
          content-class="black--text rounded-xl"
          color="white"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.deleteRequestedAt"
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete-clock
            </v-icon>
          </template>
          <div class="pa-1">
            Deletion requested on
            {{
              new Date(item.deleteRequestedAt).toLocaleDateString()
            }}
            {{
              item.deleteRequestedBy
                ? `by ${item.deleteRequestedBy.email}`
                : ''
            }}
          </div>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { objectHasSearch } from '@/utils/search';
import { CurrentCourseContent } from '@/graphql/CourseContent.gql';

export default {
  name: 'PagedLearners',
  props: {
    learners: {
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    archived: {
      type: Boolean,
      default: false
    },
    totalPages: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 0
    },
    totalElements: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    search: '',
    searchDebounce: null,
    headers: [
      { text: 'Name', value: 'firstName' },
      { text: 'Email', value: 'email' },
      { text: '', value: 'editColumn', sortable: false, align: 'end' }
    ],
    options: {
      page: 1
    }
  }),
  apollo: {
    currentCourseContent: {
      query: CurrentCourseContent
    }
  },
  computed: {
    organisationId() {
      return this.$route.params.organisationId;
    }
  },
  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.options.page = val + 1;
      }
    },
    search(newValue) {
      console.log('Search changed in PagedLearners:', newValue);
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce);
      }
      this.searchDebounce = setTimeout(() => {
        console.log('Emitting search-changed:', newValue);
        this.$emit('search-changed', newValue);
      }, 300);
    }
  }
};
</script>
