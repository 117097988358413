var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.open, width: "500" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-card-title",
            { staticClass: "h6 font-weight-bold primary--text" },
            [_vm._v(" New Bundle ")]
          ),
          _c(
            "v-card-text",
            { staticClass: "py-2" },
            [
              _c(
                "v-form",
                {
                  ref: "createBundleForm",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-label", [_vm._v("Name")]),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules.requiredField,
                      label: "Name",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _c("v-label", [_vm._v("Bundle Code")]),
                  _c("v-text-field", {
                    staticClass: "bundle-code-text-input",
                    attrs: {
                      rules: _vm.rules.bundleCode,
                      label: "Bundle Code",
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      required: ""
                    },
                    model: {
                      value: _vm.bundleCode,
                      callback: function($$v) {
                        _vm.bundleCode = $$v
                      },
                      expression: "bundleCode"
                    }
                  }),
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: { label: "Mark the bundle as active", inset: "" },
                    model: {
                      value: _vm.active,
                      callback: function($$v) {
                        _vm.active = $$v
                      },
                      expression: "active"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { color: "secondary" },
                  on: { click: _vm.createBundle }
                },
                [_vm._v(" Create ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }