<template>
  <span>
    <v-btn
      :icon="overrideText ? false : true"
      @click="confirmDelete"
      color="error"
      :disabled="
        disabled ||
          courseContent?.state === 'PUBLISHED' ||
          courseContent?.state === 'UNAVAILABLE'
      "
      :small="small"
    >
      <v-icon
        :class="overrideText ? 'mr-1' : ''"
        :size="overrideIconSize ?? '20'"
        >{{ overrideIcon ?? 'mdi-delete-outline' }}</v-icon
      >
      {{ overrideText }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="290">
      <v-card class="rounded-lg pa-2">
        <v-card-title class="h6 font-weight-bold primary--text">
          Are you sure?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="red darken-1 white--text" @click="deleteItem">
            <v-icon class="mr-1">{{
              overrideIcon ?? 'mdi-trash-can-outline'
            }}</v-icon
            >{{ overrideText ?? 'Delete' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { CourseContent } from '@/graphql/CourseContent.gql';

export default {
  name: 'DeleteButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    overrideIcon: {
      type: String,
      default: undefined
    },
    overrideText: {
      type: String,
      default: undefined
    },
    small: {
      type: Boolean,
      default: false
    },
    overrideIconSize: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  apollo: {
    courseContent: {
      query: CourseContent,
      variables() {
        return {
          id: this.$route.params.courseContentId
        };
      },
      skip() {
        return !this.$route.params.courseContentId;
      }
    }
  },
  methods: {
    confirmDelete() {
      this.dialog = true;
    },
    deleteItem() {
      this.$emit('delete');
      this.dialog = false;
    }
  }
};
</script>
