var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$apollo.queries.question.loading
    ? _c("div", [_vm._v("Loading...")])
    : _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "primary--text mb-5", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mr-auto", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mr-2 ml-n2",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.go(-1)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-arrow-left")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mr-auto",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c("h1", [
                                        _vm._v(_vm._s(_vm.lesson?.title) + ":")
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mr-auto ml-2",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "h1",
                                        [
                                          _c("edit-field", {
                                            attrs: {
                                              readonly: !_vm.courseContentId,
                                              "current-value":
                                                _vm.question?.title
                                            },
                                            on: {
                                              "update-value": _vm.updateTitle
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("breadcrumbs", {
                                        attrs: {
                                          items: [
                                            "Lessons",
                                            _vm.lesson?.title,
                                            _vm.question?.title
                                          ]
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass:
                                            "font-weight-bold text-caption",
                                          attrs: { small: "", color: "primary" }
                                        },
                                        [
                                          _vm._v(
                                            "v" + _vm._s(_vm.question?.version)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto d-flex", attrs: { cols: "auto" } },
                    [
                      _c("question-timeline-modal", {
                        attrs: {
                          "question-reference-id": _vm.question?.referenceId,
                          "current-version": _vm.question?.version
                        }
                      }),
                      _vm.isBranching &&
                      _vm.courseContentId &&
                      !_vm.isFrozenContent
                        ? _c("create-branch-modal", {
                            attrs: {
                              "is-first-node":
                                _vm.question.variations.length === 0
                            },
                            on: { "add-variation": _vm.addVariation }
                          })
                        : !_vm.isBranching &&
                          _vm.courseContentId &&
                          !_vm.isFrozenContent
                        ? _c("create-variation-modal", {
                            on: { "add-variation": _vm.addVariation }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-10" },
                [
                  _vm.question.variations.length === 0
                    ? _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isBranching
                                    ? "There are no branches for this question."
                                    : "There are no variations for this question."
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _c("div", [
                        _vm.isBranching
                          ? _c(
                              "div",
                              _vm._l(_vm.question.variations, function(
                                variation,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "mb-10" },
                                  [
                                    _c("branching-node", {
                                      attrs: {
                                        "is-frozen-content":
                                          _vm.isFrozenContent,
                                        variation: variation,
                                        index: index,
                                        nodes: _vm.question.variations
                                      },
                                      on: {
                                        "node-updated": _vm.updateQuestion,
                                        "delete-node": _vm.deleteVariation
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              _vm._l(_vm.question.variations, function(
                                variation,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "mb-10" },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "align-center mb-0",
                                        attrs: { align: "center" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "text--primary font-weight-bold text-h6"
                                          },
                                          [
                                            _vm._v(
                                              " Variation " +
                                                _vm._s(index + 1) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _vm.courseContentId &&
                                        !_vm.isFrozenContent
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "error",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteVariation(
                                                          variation.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Delete")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "rounded-lg white px-5 py-1 text--primary"
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "info-row py-4",
                                            attrs: {
                                              "no-gutters": "",
                                              align: "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "mr-auto title-column",
                                                attrs: { cols: "auto" }
                                              },
                                              [_vm._v(" Question Type ")]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "ml-auto text-capitalize",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      variation.variationType
                                                        ?.replaceAll("_", " ")
                                                        ?.toLowerCase()
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "info-row py-2",
                                            attrs: {
                                              "no-gutters": "",
                                              align: "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "mr-auto title-column",
                                                attrs: { cols: "auto" }
                                              },
                                              [_vm._v(" Question Text ")]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "ml-auto",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      id: "question-text"
                                                    }
                                                  },
                                                  [
                                                    _c("edit-field", {
                                                      attrs: {
                                                        readonly: !_vm.courseContentId,
                                                        type: "richtext",
                                                        "current-value":
                                                          variation.questionText
                                                      },
                                                      on: {
                                                        "update-value": newValue =>
                                                          _vm.updateQuestionText(
                                                            newValue,
                                                            index
                                                          )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "info-row py-2",
                                            attrs: {
                                              "no-gutters": "",
                                              align: "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "mr-auto title-column",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _vm._v(
                                                  " Incorrect Answer Feedback "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "ml-auto",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _c("edit-field", {
                                                  attrs: {
                                                    readonly: !_vm.courseContentId,
                                                    "current-value":
                                                      variation.incorrectAnswerFeedback
                                                  },
                                                  on: {
                                                    "update-value": newValue =>
                                                      _vm.updateIncorrectAnswerFeedback(
                                                        newValue,
                                                        index
                                                      )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    variation.variationType ==
                                    _vm.variantType.SINGLE_TEXT_CHOICE
                                      ? _c("single-text-choice", {
                                          attrs: {
                                            "is-frozen-content":
                                              _vm.isFrozenContent,
                                            "existing-options":
                                              variation.options
                                          },
                                          on: {
                                            "options-updated": function(
                                              $event
                                            ) {
                                              return _vm.updateOptionsForVariation(
                                                $event,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    variation.variationType ==
                                    _vm.variantType.SINGLE_IMAGE_CHOICE
                                      ? _c("single-image-choice", {
                                          attrs: {
                                            "is-frozen-content":
                                              _vm.isFrozenContent,
                                            "existing-options":
                                              variation.options
                                          },
                                          on: {
                                            "options-updated": function(
                                              $event
                                            ) {
                                              return _vm.updateOptionsForVariation(
                                                $event,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    variation.variationType ==
                                    _vm.variantType.MULTIPLE_TEXT_CHOICE
                                      ? _c("multiple-text-choice", {
                                          attrs: {
                                            "is-frozen-content":
                                              _vm.isFrozenContent,
                                            "existing-options":
                                              variation.options
                                          },
                                          on: {
                                            "options-updated": function(
                                              $event
                                            ) {
                                              return _vm.updateOptionsForVariation(
                                                $event,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    variation.variationType ==
                                    _vm.variantType.MULTIPLE_IMAGE_CHOICE
                                      ? _c("multiple-image-choice", {
                                          attrs: {
                                            "is-frozen-content":
                                              _vm.isFrozenContent,
                                            "existing-options":
                                              variation.options
                                          },
                                          on: {
                                            "options-updated": function(
                                              $event
                                            ) {
                                              return _vm.updateOptionsForVariation(
                                                $event,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    variation.variationType ==
                                    _vm.variantType.ORDER_LIST
                                      ? _c("order-list", {
                                          attrs: {
                                            "is-frozen-content":
                                              _vm.isFrozenContent,
                                            "existing-options":
                                              variation.options
                                          },
                                          on: {
                                            "options-updated": function(
                                              $event
                                            ) {
                                              return _vm.updateOptionsForVariation(
                                                $event,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    variation.variationType ==
                                    _vm.variantType.DRAG_AND_DROP
                                      ? _c("drag-and-drop", {
                                          attrs: {
                                            "is-frozen-content":
                                              _vm.isFrozenContent,
                                            "existing-options":
                                              variation.options
                                          },
                                          on: {
                                            "options-updated": function(
                                              $event
                                            ) {
                                              return _vm.updateOptionsForVariation(
                                                $event,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }