var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-3",
                          attrs: {
                            color: "primary",
                            small: _vm.dense || !!_vm.routeCourseId
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { class: _vm.routeCourseId ? "" : "mr-2" }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v(
                        _vm._s(_vm.routeCourseId ? "Add" : "New") + " Module"
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-card-title",
                { staticClass: "h6 font-weight-bold primary--text" },
                [_vm._v(" New Module ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-form",
                    { ref: "createModuleForm" },
                    [
                      _c("v-label", [_vm._v("Title")]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          label: "Title",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.title,
                          callback: function($$v) {
                            _vm.title = $$v
                          },
                          expression: "title"
                        }
                      }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-label", [_vm._v("Card Title Colour")]),
                              _c("colour-picker", {
                                attrs: {
                                  label: "Card Title Colour",
                                  "current-colour": _vm.cardTitleColour
                                },
                                on: {
                                  change: function($event) {
                                    _vm.cardTitleColour = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-label", [_vm._v("Card Subtext Colour")]),
                              _c("colour-picker", {
                                attrs: {
                                  label: "Card Subtext Colour",
                                  "current-colour": _vm.cardSubtextColour
                                },
                                on: {
                                  change: function($event) {
                                    _vm.cardSubtextColour = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-label", [_vm._v("Card Background Colour")]),
                              _c("colour-picker", {
                                attrs: {
                                  label: "Card Background Colour",
                                  "current-colour": _vm.cardBackgroundColour
                                },
                                on: {
                                  change: function($event) {
                                    _vm.cardBackgroundColour = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-label", [_vm._v("Progress Fill Colour")]),
                              _c("colour-picker", {
                                attrs: {
                                  label: "Progress Fill Colour",
                                  "current-colour": _vm.progressFillColour
                                },
                                on: {
                                  change: function($event) {
                                    _vm.progressFillColour = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-label", [_vm._v("Course")]),
                      _c("v-select", {
                        attrs: {
                          rules: _vm.rules.requiredField,
                          items: _vm.courseItems,
                          disabled: !!_vm.routeCourseId,
                          label: "Course",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          required: ""
                        },
                        model: {
                          value: _vm.courseId,
                          callback: function($$v) {
                            _vm.courseId = $$v
                          },
                          expression: "courseId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: {
                        color: "secondary",
                        disabled: _vm.creatingModule,
                        loading: _vm.creatingModule
                      },
                      on: { click: _vm.createModule }
                    },
                    [_vm._v(" Create ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }