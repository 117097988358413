var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editing
    ? _c(
        "div",
        [
          _c(
            "v-row",
            { attrs: { align: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm.type === "text"
                    ? _c("v-text-field", {
                        attrs: {
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          autofocus: ""
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm.type === "select"
                    ? _c("v-select", {
                        attrs: {
                          items: _vm.selectItems,
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm.type === "richtext"
                    ? _c("tiptap-vuetify", {
                        staticClass: "mb-4",
                        attrs: { extensions: _vm.extensions },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm.type === "file"
                    ? _c("v-file-input", {
                        ref: "fileInput",
                        staticStyle: { "min-width": "200px" },
                        attrs: {
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        on: { change: _vm.validateFile },
                        model: {
                          value: _vm.file.file,
                          callback: function($$v) {
                            _vm.$set(_vm.file, "file", $$v)
                          },
                          expression: "file.file"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { icon: "" },
                      on: { click: _vm.updateValue }
                    },
                    [_c("v-icon", [_vm._v("mdi-check-bold")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "v-row",
            {
              class: `${
                _vm.courseContent?.state == "PUBLISHED" ||
                _vm.readonly ||
                _vm.isFrozenContent
                  ? "py-2"
                  : ""
              }`,
              attrs: { align: "center", "no-gutters": "" }
            },
            [
              _vm.type == "richtext"
                ? _c("v-col", [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.currentValue) }
                    })
                  ])
                : _c(
                    "v-col",
                    {
                      class: `${_vm.textClass} ${
                        _vm.type === "select" ? "text-capitalize" : ""
                      }`,
                      attrs: { cols: "auto" }
                    },
                    [
                      _vm._t("default"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.displayValue
                              ? _vm.displayValue
                              : _vm.currentValue
                              ? _vm.currentValue
                              : "-"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
              !_vm.readonly && !_vm.isFrozenContent
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            disabled: _vm.readonly || _vm.isFrozenContent,
                            icon: ""
                          },
                          on: { click: _vm.startEditing }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              _vm._s(_vm.addIcon ? "mdi-plus" : "mdi-pencil")
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }