var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", top: "", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ on, attrs }) {
            return [
              _c(
                "v-row",
                {
                  class: `${
                    _vm.readonly || !_vm.isFrozenContent ? "py-0" : "py-2"
                  }`,
                  attrs: { align: "center", "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      class: `${_vm.readonly ? "pa-0 mr-2" : ""}`,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "rounded-lg mr-2",
                              style: `width: 25px; height: 25px; background-color: ${_vm.currentColour}`
                            },
                            "div",
                            attrs,
                            false
                          ),
                          !_vm.readonly ? on : undefined
                        )
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: `${_vm.readonly ? "pa-0" : ""} mr-2`,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text--primary text-uppercase" },
                        [_vm._v(" " + _vm._s(_vm.currentColour) + " ")]
                      )
                    ]
                  ),
                  !_vm.readonly && !_vm.isFrozenContent
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "", icon: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-pencil")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c("v-color-picker", {
        attrs: {
          value: _vm.currentColour,
          "dot-size": "25",
          "hide-inputs": "",
          "hide-mode-switch": "",
          mode: "hexa",
          width: "300px"
        },
        on: { input: _vm.debouncedColourChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }